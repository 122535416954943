import { clear, setItem } from '../../utils/storage';
import { getWin, getUserId } from '../../utils/userprofile';
import { localStorageConstants } from '../../constants/LocalStorageConstants';
import { checkUserImpersonation } from "../../dao/user/impersonationpermissionscheck";
import { getItem } from '../../utils/storage';
import { getUserDefaultUrl } from '../../utils/common';

export default class Authorization {

  checkPermission = async (path) => {

    let flag = false;

    if (path.indexOf('impersonate') > 0 && window.ENV.REACT_APP_IMPERSONATION_CHECK === "true") {

      const permission = await checkUserImpersonation(getUserId(), getWin());

      if (await permission) {
        flag = permission === 'true' ? true : false;
        if (flag) {
          clear();
          setItem(localStorageConstants.USER_LOGIN, true);
          setItem(localStorageConstants.IMPERSONATE_USER, true);
        } else {
          clear();
        }
        return await flag;
      } else {
        clear();
        return false;
      }
    } else {
      return true;
    }
  };

  checkPilotAcademy = async () => {
      const requestedPath = getUserDefaultUrl();
      setItem(localStorageConstants.REQUESTED_PATH, requestedPath);
      window.location.href = requestedPath;
  };

  setAemDisplay = async () => {
    const aemIframe = window.ENV.REACT_APP_AMEIFRAME_DISPLAY;
    const isExternaluser = getItem(localStorageConstants.IS_EXTERNAL_USER)
    if (isExternaluser === 'true') {
      setItem(localStorageConstants.AEMIFRAMEDISPLAY, 'false');
    } else if(aemIframe !=null && aemIframe && aemIframe != 'false') {
      setItem(localStorageConstants.AEMIFRAMEDISPLAY, 'true');
    } else {
      setItem(localStorageConstants.AEMIFRAMEDISPLAY, 'false');
    }
  };
}