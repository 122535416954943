import React from 'react';
import Header from '../../components/common/header';
import { localStorageConstants } from '../../constants/LocalStorageConstants';
import { pageViewEvent, setMappingKey, setPageName } from '../../utils/userTelemetry';
import { currentUTCtimestamp } from '../../utils/common';
import { setItem } from '../../utils/storage';
import { getCurrentMappingKey } from '../../utils/userprofile';
export function commonRoute(Component) {
	return class SecurityFilter extends React.Component {
		/**
     * Render
     */
	
	componentDidMount() {
		setPageName(this.props.match.path);
		this.startDateTimeUTC = currentUTCtimestamp();
		this.startDate = new Date();
		setMappingKey();
		pageViewEvent(); 
		setItem(localStorageConstants.SOURCEMAPPINGKEY,getCurrentMappingKey());
  
	  }
		render() {
			const pageContainer = {
				left: 0,
				top: 0,
				right: 0,
				// position: 'relative', /* This is causing issue in showing the PDF content */
				// height:'100vh',
				minHeight: '100vh', 
				// display: 'flex',
				// flexDirection: 'column',
				background: '#f7f7f7',
				width:'100%',
				overflowX:'hidden',
				'@media (min-width: 0px) and (max-width: 640px)': {
					width:'auto',
				   },
			};
			const headerContainer = {
				// paddingBottom: '110px'
				// This is causing UI issue, hence commented
			};

			return (
				<React.Fragment>
					<div style={pageContainer}>
						<div style={headerContainer}>
							<Header />
							<Component {...this.props} />
						</div>
					</div>
				</React.Fragment>
			);
		}
	};
}

export default commonRoute;
