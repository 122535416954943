export const Messages = {
    common_error_msg: 'Sorry, something went wrong. Please try again later.',
    get_assessment_failed: 'The assessment was not found or has not been setup. Please try again.',

    test_submit_error: 'Invalid error. Submit Test Error',
    not_enrolled: 'Not enrolled at this time.  Try again later.',
    pre_already_taken: 'PRE Assessment completed.',
    passed_assessment: 'Assessment completed.',
    no_post_assessment: 'You do not have access to Post Assessment at this time.  Try again later',
    no_assessments: 'No Assessments currently.  Try again later',
    maximum_attempts: 'You have taken this assessment 2 times. This is the maximum number allowed.',
    retake_window_not_open_yet: 'You do not have access at this time.  Try again later',
    first_retake_window_exceeded: 'First retake window exceeded.  Try again later',
    second_retake_window_exceeded: 'Second retake window exceeded.  Try again later',
    unknown_error: 'Invalid error.',
    no_file_data_error: 'No file data available in the response',
    activate_account: 'Please enter password to activate your account',
    token_missing: 'Expected token missing. please click the link again',
    activation_link_expired: 'Activation link expired.',
    resend_email_error: 'Email not present in the system. Please retry or signup',
    assessment_session_error:
        `The assessment has been started/submitted on a different device or window.
        If you believe this is an error, please reach out to your Academy Coordinator or Program Manager.`
}
