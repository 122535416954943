import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { TimeoutDialog } from './timeoutDialog';
import Auth from '../../security/authentication';
import { refreshToken } from '../../dao/user/userprofiledata';

export const IdleTimerContainer = () => {
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const idleTimeoutTime = +window.ENV.REACT_APP_IDLE_TIMEOUT;
    const dialogTimeoutTime = +window.ENV.REACT_APP_DIALOG_TIMEOUT;
    const auth = new Auth();

    const stayActive = () => {
        setIsOpen(false);
        clearTimeout(sessionTimeoutRef.current);
        refreshToken();
    }

    const logOut = () => {
        setIsOpen(false);
        clearTimeout(sessionTimeoutRef.current);
        auth.logout();
    }

    const onIdle = () => {
        if(window.location.pathname === '/login'){
            clearTimeout(sessionTimeoutRef.current);
        } else{
            setIsOpen(true);
            sessionTimeoutRef.current = setTimeout(logOut, dialogTimeoutTime);
        }
    }

    return (
        <div id="idle-timeout-container">
            <TimeoutDialog open={isOpen} logOut={logOut} stayActive={stayActive} />
            <IdleTimer id="idle-timer" ref={idleTimerRef} timeout={idleTimeoutTime} onIdle={onIdle}></IdleTimer>
        </div>
    )
};