export default (() => {

    const _defaultReportingId = "AcademyEDU";
    const _pulseDataKey = 'pd';

    /**
     * Queues the telemetry information to be sent to pulse via beacon js
     * API: https://confluence.walmart.com/pages/viewpage.action?pageId=186301670#id-3.beacon.jsAPIs-3.1_tagAction
     * Sent telemetry information can be debugged using: https://confluence.walmart.com/pages/viewpage.action?pageId=11617458#BeaconDetails-Frontend
     * @param {*} actionContext the page where the telemetry info originated
     * @param {*} actionName the name of the user action which triggered telemetry capture
     * @param {*} data the set of attributes and data associtated with the telemtry capture
     * @param {*} reportingId the reporting id for the context, defaults to {@link _defaultReportingId}
     * Note: Please check console logs in case pulse events are not displayed in slunk. 
     */
    const send = (actionContext, actionName, data, reportingId = _defaultReportingId) => {
        var _bcq = window._bcq || []; // To handle errors in case global _bcq is not initialized properly in index.html
        if (!actionContext || !actionName || !data) {
            console.error("Error in sending pulse telemetry missing on of actionContext, actionName, data.");
        } else {
            _bcq.push(
                [
                    '_tagAction',
                    actionContext,
                    actionName,
                    reportingId,
                    {
                        [_pulseDataKey]: data,
                    }
                ]
            );
        }
    }

    return {
        send
    };

})();
