/**
     * Send app events data to appinsights
     * @param {*} actionName the name of the user action
     * @param {*} data the set of attributes and data associtated with the user event
     */
export const send = (actionName, data) => {
  actionName === 'pageViewEvent' ?
    window.appInsights.trackPageView({
      properties: data,
    }) : window.aisdk.trackEvent({
      name: actionName,
      properties: data,
    })
};