import { restService } from '../../apiUtils/datalibrary';
import axios from 'axios';
import { getHeaders, getServiceDomain } from "../../services";
import { getWin, getRole } from '../../userprofile';

axios.defaults.withCredentials = true

export const callService = async (apiPath, params, headers, methodType) => {
  const url = await getServiceDomain("app") + apiPath;
  const commonHeaders = await getHeaders();
  const response = await restService(
    url,
    params,
    commonHeaders,
    methodType
  )
  return response;
}

export const callExternalService = async (apiPath, params, headers) => {
  const url = window.ENV.REACT_APP_LEGACY_APP + apiPath;
  const httpClient = axios.create();
  httpClient.defaults.timeout = 120000;
  return await httpClient.post(url, params,
    { headers: headers }).then(response => {
      return response;
    }).catch(error => {
      console.log("rest api post error==>", error);
      return null;
    });
}

export const callExternalServicePut = async (apiPath, params) => {
  const url = window.ENV.REACT_APP_LEGACY_APP + apiPath;
  const headers = { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", "win": getWin(), "role": getRole() };
  return await axios.put(url, params,
    { headers: headers }).then(response => {
      return response;
    }).catch(error => {
      console.log("rest api put error==>", error);
      return null;
    });
}

export const callExternalServiceDel = async (apiPath, headers) => {
  const url = window.ENV.REACT_APP_LEGACY_APP + apiPath;
  return await axios.delete(url, { headers: headers }).then(response => {
    return response;
  }).catch(error => {
    console.log("rest api del error==>", error);
    return null;
  });
}

export const callExternalServicePost = async (apiPath, params) => {
  const url = await getServiceDomain("app") + apiPath;
  const headers = await getHeaders();
  return await axios.post(url, params,
    { headers: headers }).then(response => {
      const responseData = response.data.responseData;
      return responseData;
    }).catch(error => {
      console.log("rest api post error==>", error);
      return null;
    });
}
