export const localStorageConstants = {
  EXTERNAL_URL_REDIRECT: 'external_url_redirect',
  AUTH_CODE: 'authcode',
  SESSION_ID: 'sessionid',
  CLOCK_STATUS: 'clockstatus',
  USER_ID: 'userid',
  //USER_NAME: 'username',
  IS_VALID_USER:'isvaliduser',
  COURSE_ROSTER_ID: 'CRI',
  ACCESS_TOKEN: 'accesstoken',
  ID_TOKEN: 'idtoken',
  JOB_CODE: 'jobcode',
  WIN: 'win',
  STORE_NO: 'storenum',
  ROLE: 'role',
  ORIGINAL_ROLE: 'originalRole',
  AREA: 'area',
  FIRST_NAME: 'firstname',
  LAST_NAME: 'lastname',
  FULL_NAME: 'fullname',
  LAWSON_JOBCODE: 'lawsonjobcode',
  FILE_ID: 'fileId',
  SCHEDULE_ID: 'scheduleId',
  ROSTER_ID: 'rosterId',
  PAGE_TYPE: 'pageType',
  DIVISION: 'division',
  DIVISION_ID: 'divisonId',
  CURRENTMAPPINGKEY: 'currentmappingkey',
  SOURCEMAPPINGKEY: 'sourcemappingkey',
  AEMIFRAMEDISPLAY: 'aemIframeDisplay',
  // this is set as tru and false and checked while login
  USER_LOGIN: 'userlogin',
  TEAM_ID: 'teamid',
  TEAM_NAME: 'teamname',
  PATH_NAME: 'pathname',
  USER_PROFILE_PRESENT: 'profilefetched',
  IMPERSONATE_USER: 'impersonateuser',
  ACADEMY_ID: 'academyid',
  PAY_TYPE: 'payType',
  REQUESTED_PATH: 'requestedpath',
  EVENTMAPPINGKEY: 'eventMappingKey',
  LOGIN_TIME: 'logintime',
  ASSESSMENT_NAME:'assessmentName',
  COURSE_NAME:'courseName',
  Title:'title',
  ASSESSMENT_IN_PROGRESS: 'assessmentInProgress',
  COUNTER_IN_PROGRESS: 'counterInProgress',
  TYPE_IN_PROGRESS: 'typeInProgress',
  DEMO_MODE: 'demo_mode',
  IS_EXTERNAL_USER: 'isExternalUser',
  SOURCE: 'source'
};
