
import { setItem } from '../../utils/storage';

export const failOverCheck = async () => {

  var commonServicesDommain = window.ENV.REACT_APP_SERVICES_DOMAIN;
  setItem("commonservicesdomain", commonServicesDommain);

  var appServicesDommain = window.ENV.REACT_APP_SERVICES_DOMAIN;
  setItem("appservicesdomain", appServicesDommain);

}