import { checkUserImpersonationQuery } from "../../../constants/QueryConstants";  
import { callService as callRestService } from "../../../utils/services/rest/common";

export const checkUserImpersonation = async (userId, win) => {
  let query = checkUserImpersonationQuery;
  query = query.replace("<<userId>>", userId).replace("<<win>>", win);
  
  const res = await callRestService('/external-service-proxy/graphql',{query: query, queryName: "checkUserImpersonation"},'','axiosPost');
  if(res && res.permission){
    return res.permission;
  } else {
    return "false";
  }
};

