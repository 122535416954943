const styles = {
    assessmentControlsContainer: {
    },
    assessmentControlsButtonContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    takePreAssessmentBtn: {
        marginRight: 10,
        width: '150px !important',
        fontSize: '14px !important',
        height: '25px !important',
        borderRadius: '50px !important',
        border: 'solid 3px #fff !important',
        background: '#0071ce !important',
        color: '#fff !important',
        '@media only screen and (min-device-width: 1025px)': {
            height: '35px !important',
        }
    },
    takeAssessmentBtn: {
        marginRight: 10,
        border: 'solid 3px #fff !important',
        width: '150px !important',
        fontSize: '14px !important',
        height: '25px !important',
        borderRadius: '50px !important',
        background: '#fff !important',
        color: '#000 !important',
        '@media only screen and (min-device-width: 1025px)': {
            height: '35px !important',
        }
    },
};

export default styles;