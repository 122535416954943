import React, { useState } from "react";
import { makeStyles, Dialog, CloseIcon } from "../../../lib/materialui";
import Button from "../../common/button";
import { validateAssessmentCode } from "../../../dao/assessment";
import { getItem } from "../../../utils/storage";
import { replaceIpadChars } from "../../../utils/common";
import { localStorageConstants } from "../../../constants/LocalStorageConstants";
import { trackValidationSubmitButtonClickEvent } from "../../../utils/userTelemetry/index";
import { commonConstants } from "../../../constants/CommonConstants";

const ValidationDialog = (props) => {
  const {
    names,
    validateAssmt,
    courseId,
    open,
    handleClose,
    handleConfirmation,
    getCode,
    type,
    passPercent = "70",
  } = props;

  const useStyles = makeStyles((theme) => styles);
  const classes = useStyles();
  const {
    dialogSize,
    container,
    closePanel,
    closeIcon,
    dialogTitle,
    ruler,
    codeLabel,
    textInput,
    form,
    submit,
    btnContainer,
    descText,
    formInput,
    uoListCntnr,
    descText2,
  } = classes;
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [submitNameError, setSubmitNameError] = useState(false);
  const [submitCodeError, setSubmitCodeError] = useState(false);

  const validateAssessment = async () => {
    const courseRoster = getItem(localStorageConstants.ROSTER_ID)
      ? getItem(localStorageConstants.ROSTER_ID)
      : "NA";
    const isTrue = await validateAssessmentCode(courseId, code, courseRoster);
    if (isTrue) {
      handleClose();
      handleConfirmation();
    } else {
      setSubmitCodeError(true);
    }
  };

  const handleSubmit = () => {
    setSubmitNameError(false);
    setSubmitCodeError(false);
    if (getCode) {
      getCode(code);
    }
    const assessmentId = getItem(localStorageConstants.ASSESSMENT_IN_PROGRESS);
    const rosterId = getItem(localStorageConstants.ROSTER_ID);
    trackValidationSubmitButtonClickEvent(
      code,
      rosterId,
      courseId,
      assessmentId
    );
    const fullName = (props.names?.first_name ?? "")
      .concat(" ", props.names?.last_name ?? "")
      .toLowerCase()
      .replace(/ +(?= )/g, "")
      .trim();
    if (replaceIpadChars(name.toLowerCase()) === fullName) {
      if (
        names.role === commonConstants.PARTICIPANT &&
        !validateAssmt.not_first_attempt
      ) {
        validateAssessment();
      } else {
        handleClose();
        handleConfirmation();
      }
    } else {
      setSubmitNameError(true);
    }
  };
  const handleInputNameOnChange = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      <Dialog
        id="assessment-validation-dialog"
        maxWidth={false}
        classes={{ paper: dialogSize }}
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
      >
        <div id="assessment-validation-dialog-content" className={container}>
          <div
            id="assessment-validation-dialog-header"
            className={closePanel}
            onClick={handleClose}
          >
            <div
              id="assessment-validation-dialog-title"
              className={dialogTitle}
            >
              {type === "Attendance"
                ? "Attendance Agreement"
                : "Assessment Agreement"}
            </div>
            <CloseIcon className={closeIcon} />
          </div>

          <div className={ruler}></div>

          <div id="assessment-validation-dialog-body" className={form}>
            <div
              id="assessment-validation-dialog-description"
              className={descText}
            >
              {type === "Attendance"
                ? `As a Walmart / Sam’s Club associate, you are expected to act with the highest level of integrity. 
                            By typing your name, you are confirming you attended this class in its entirety.`
                : `As a Walmart / Sam’s Club associate, you are expected to act with the highest level of integrity.
                            Cheating, in any form, is not permitted and will result in disciplinary action up to and including
                            termination.`}
            </div>

            {type === "Attendance" ? (
              <div className={codeLabel} />
            ) : (
              <div>
                <div
                  id="assessment-validation-examples"
                  className={uoListCntnr}
                >
                  <div
                    id="assessment-validation-examples-label"
                    className={descText}
                  >
                    Some examples of cheating include:
                  </div>
                  <ul
                    id="assessment-validation-examples-list"
                    className={descText}
                  >
                    <li>
                      Using notes or any other document created by another
                      associate.
                    </li>
                    <li>Discussing questions and/or possible answers.</li>
                    <li>Viewing another associates test answers.</li>
                  </ul>
                </div>
                <div
                  id="assessment-validation-dialog-description-second"
                  className={descText2}
                >
                  All participants will have 2 attempts to take the post assessment. 
                  Selected Academy classes are eligible for credit towards an LBU program. 
                  If you are interested in earning credit towards an LBU program, 
                  you must score a {passPercent}% or above on the post assessment.
                </div>
              </div>
            )}
            <div
              id="assessment-validation-name-input-label"
              className={descText}
            >
              Please enter your name ({names?.first_name} {names?.last_name}) to
              confirm that you agree to the terms.
            </div>

            <div
              id="assessment-validation-name-input-container"
              className={formInput}
            >
              <div id="assessment-validation-name-label" className={codeLabel}>
                Name
              </div>
              <div id="assessment-validation-name-input">
                <input
                  className={textInput}
                  autoFocus
                  type="text"
                  id="name"
                  onChange={handleInputNameOnChange}
                  onBlur={handleInputNameOnChange}
                  value={name}
                />
              </div>
              {submitNameError && (
                <div
                  id="assessment-validation-error-div"
                  style={{ color: "red", fontSize: 16 }}
                >
                  The name entered is incorrect. Please try again.
                </div>
              )}
            </div>

            {names.role === commonConstants.PARTICIPANT &&
            !validateAssmt.not_first_attempt ? (
              <div
                id="assessment-validation-dialog-code-input-container"
                className={formInput}
              >
                <div
                  id="assessment-validation-dialog-code-input-message"
                  className={codeLabel}
                >
                  Enter the six digit code provided by your facilitator
                </div>
                <div id="assessment-validation-dialog-code-input">
                  <input
                    placeholder={"PIN"}
                    maxLength={6}
                    className={textInput}
                    type="text"
                    id="code"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                  />
                </div>
                {submitCodeError && (
                  <div
                    id="assessment-validation-dialog-wrong-code-message"
                    style={{ color: "red" }}
                  >
                    You entered the wrong code. Please try again
                  </div>
                )}
              </div>
            ) : null}
            <div
              id="assessment-validation-dialog-buttons"
              className={btnContainer}
            >
              <Button
                id="assessment-validation-dialog-button-submit"
                openEvent={handleSubmit}
                className={submit}
                buttonText={"Accept"}
                buttonType={"primary"}
                buttonStyle={"pill"}
                buttonSize={"small"}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const styles = {
  formInput: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    fontFamily: "Bogle",
    fontSize: 18,
    justifyContent: "center",
    width: 394,
    "@media (min-width:0px) and (max-width:640px)": {
      width: "auto",
      alignSelf: "auto",
    },
  },
  uoListCntnr: {
    margin: "25px 0 15px 0",
  },
  descText2: {
    paddingBottom: 15,
    fontFamily: "Bogle",
    fontSize: 18,
  },
  descText: {
    fontFamily: "Bogle",
    fontSize: 18,
    listStyle: "none",
    "& li::before": {
      content: '"."',
      fontSize: 77,
      lineHeight: 0,
      marginRight: 5,
      position: "relative",
      top: -2,
    },
  },

  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    // marginTop:39
  },
  submit: {
    margin: "20px 0 17px 0",
    border: "solid 3px #000",
  },
  codeLabel: {
    fontFamily: "Bogle",
    fontSize: 18,
    marginTop: 20,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    padding: 20,
    // lineHeight: '25px'
  },
  textInput: {
    fontFamily: "Bogle",
    fontSize: 18,
    width: "100%",
    border: "none",
    borderBottom: "solid 1px silver",
    "&:focus": {
      outline: 0,
    },
  },
  formLabel: {
    fontFamily: "Bogle",
    fontSize: 16,
  },
  closeIcon: {
    fontSize: 38,
    color: "#c9c9c9",
    cursor: "pointer",
  },
  dialogTitle: {
    fontFamily: "Bogle",
    fontWeight: "bold",
    fontSize: 24,
    color: "#041c2c",
    display: "flex",
    margin: "0 0 0 20px",
    flexDirection: "row",
    justifyContent: "center",
    "@media (min-width:0px) and (max-width:640px)": {
      fontSize: 18,
    },
    "@media (min-width:641px) and (max-width:1024px)": {
      fontSize: 23,
    },
  },
  ruler: {
    borderBottom: "solid 2px #ededed",
    margin: "0 17px 0px 17px",
    "@media (min-width:0px) and (max-width:640px)": {
      margin: "0 17px 9px 17px",
    },
    "@media (min-width:641px) and (max-width:1024px)": {
      margin: "0 17px 14px 17px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  closePanel: {
    display: "flex",
    justifyContent: "space-between",
    padding: "7px 7px 0 0",
    marginBottom: 6,
    alignItems: "center",
    "@media (min-width:0px) and (max-width:640px)": {
      marginBottom: 0,
    },
  },

  dialogSize: {
    width: "739px",
    borderRadius: 13,
    "@media (min-width:0px) and (max-width:640px)": {
      width: "100%",
    },
  },
};

export default ValidationDialog;
