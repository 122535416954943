import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import { securityFilter } from './security/filter';
import { getRequestedPath, getRole } from './utils/userprofile';
import { getUserDefaultUrl } from './utils/common';
import { commonRoute } from './routes/common'
import { IdleTimerContainer } from './components/idletimeout';
import Loading from './components/loading';
import {commonConstants} from './constants/CommonConstants';
import { createMuiTheme, MuiThemeProvider } from './lib/materialui'

const requestedPath = getRequestedPath() || `${window.location.pathname}${window.location.search}`;

const role = getRole() || '';

const UnauthorizedPage = lazy(() => import('./pages/unauthorized'));
const ImpersonateUserPage = lazy(() => import('./pages/impersonateuser'));
const courseArchivedPage = lazy(() => import('./components/course/commonErrMsg'));
const UserNotFound =lazy(()=>import('./components/usernotfound'));
const HomePage = lazy(() => import('./pages/home'));
const Course = lazy(() => import('./components/course'));
const dynamicAdmin = lazy(() => import('./components/admin'));
const fileDisplay = lazy(() => import('./components/admin/common/fileDisplay'));
const vcFileDisplay = lazy(() => import('./components/admin/viewcontent/fileDisplay'));
const HealthCheckPage = lazy(() => import("./components/common/healthcheck"));
const LogoutPage = lazy(() => import("./components/common/logout"));
const Roster = lazy(() => import('./components/roster'));
const PDFModules = lazy(() => import('./pages/facilitator/modules'));
const Documents = lazy(() => import('./pages/facilitator/documents'));
const PDFFileDisplay = lazy(() => import('./pages/facilitator/pdfpage/fileDisplay'));
const aemIframePage = lazy(() => import("./components/aemiframepage"));
const touchEventPage = lazy(() => import("./components/touchevent"));
const signIn = lazy(() => import("./components/externaluser/login"));
const signUp = lazy(() => import("./components/externaluser/signup"));
const forgotPassword = lazy(() => import("./components/externaluser/forgotpassword"));
const resetPassword = lazy(() => import("./components/externaluser/resetpassword"));
const activateAccount = lazy(() => import("./components/externaluser/activateaccount"));
const noMatch = lazy(() => import("./components/common/nomatch"));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0071ce',
      dark: '#0457bc',
      light: '#b3d1f7'
    },
    common: {
      white: '#FFFFFF',
      black: '#000000'
    }
  },
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <div className="App">

        <IdleTimerContainer></IdleTimerContainer>
        <Suspense fallback={<Loading title={'Loading'} />}>
          {role === '' &&
            <Switch>
              <Route exact path="/healthchecker" component={HealthCheckPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <Route exact path="/*localLogout*" component={LogoutPage} />
              <Route exact path="/usernotfound" component={UserNotFound} />
              <Route exact path="/unauthorized" component={commonRoute(UnauthorizedPage)} />
              <Route exact path="/aempage" component={aemIframePage} />
              <Route exact path="/toucheventpage" component={touchEventPage} />
              <Route exact path="/resetpassword" component={resetPassword} />
              <Route exact path="/activateaccount" component={activateAccount} />
              <Route exact path="/login" component={signIn} />
              <Route exact path="/signup" component={signUp} />
              <Route exact path="/forgotpassword" component={forgotPassword} />
              <Route component={noMatch} />
            </Switch>
          }

          {(role === commonConstants.PARTICIPANT || role ===  commonConstants.FACILITATOR) &&
            <Switch>
              <Route exact path="/healthchecker" component={HealthCheckPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <Route exact path="/*localLogout*" component={LogoutPage} />
              <Route exact path="/unauthorized" component={commonRoute(UnauthorizedPage)} />
              <Route exact path="/courseArchived" component={courseArchivedPage} />
              <Route exact path="/home" component={commonRoute(HomePage)} />
              <Route exact path="/course/:id" component={commonRoute(Course)} />
              <Route exact path="/facilitator/roster/:id" component={commonRoute(Roster)} />
              <Route exact path="/facilitator/modules" component={commonRoute(PDFModules)} />
              <Route exact path="/facilitator/modules/documents" component={commonRoute(Documents)} />
              <Route exact path="/facilitator/modules/documents/file" component={(PDFFileDisplay)} />
              <Route exact path="/aempage" component={aemIframePage} />
              <Route exact path="/toucheventpage" component={touchEventPage} />
              <Route exact path="/resetpassword" component={resetPassword} />
              <Route component={noMatch} />
            </Switch>}


          {(role ===  commonConstants.SUPER_ADMIN || role === commonConstants.ADMIN) &&
            <Switch>
              <Route exact path="/healthchecker" component={HealthCheckPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <Route exact path="/*localLogout*" component={LogoutPage} />
              <Route exact path="/impersonateuser" component={ImpersonateUserPage} />
              <Route exact path="/unauthorized" component={commonRoute(UnauthorizedPage)} />
              <Route exact path="/admin/managecontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managearchivecontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/course/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/folder/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/folder/:fid/module/:mid" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/detailpage/viewpage" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/viewpage/affectedpage" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/course/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:fid/module/:mid" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/pendingreleases" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/actionplans" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/users" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/fileDisplay/:fileId/:version" component={commonRoute(fileDisplay)} />
              <Route exact path="/admin/vc/fileDisplay/:fileId/:version" component={(vcFileDisplay)} />
              <Route exact path="/aempage" component={aemIframePage} />
              <Route exact path="/toucheventpage" component={touchEventPage} />

              <Route component={noMatch} />
            </Switch>}

          {(role === commonConstants.CONTENT_MANAGER) &&
            <Switch>
              <Route exact path="/healthchecker" component={HealthCheckPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <Route exact path="/*localLogout*" component={LogoutPage} />
              <Route exact path="/unauthorized" component={commonRoute(UnauthorizedPage)} />
              <Route exact path="/admin/managecontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/course/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/folder/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/folder/:fid/module/:mid" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/detailpage/viewpage" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/managecontent/viewpage/affectedpage" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/course/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:fid/module/:mid" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/pendingreleases" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/users" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/fileDisplay/:fileId/:version" component={commonRoute(fileDisplay)} />
              <Route exact path="/admin/vc/fileDisplay/:fileId/:version" component={vcFileDisplay} />
              <Route exact path="/aempage" component={aemIframePage} />
              <Route exact path="/toucheventpage" component={touchEventPage} />

              <Route component={noMatch} />
            </Switch>}

          {(role === commonConstants.COORDINATOR) &&
            <Switch>
              <Route exact path="/healthchecker" component={HealthCheckPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <Route exact path="/*localLogout*" component={LogoutPage} />
              <Route exact path="/unauthorized" component={commonRoute(UnauthorizedPage)} />
              <Route exact path="/admin/viewcontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/course/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:fid/module/:mid" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/actionplans" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/vc/fileDisplay/:fileId/:version" component={(vcFileDisplay)} />
              <Route exact path="/aempage" component={aemIframePage} />
              <Route exact path="/toucheventpage" component={touchEventPage} />

              <Route component={noMatch} />
            </Switch>}

          {(role === commonConstants.OPERATION_MANAGER) &&
            <Switch>
              <Route exact path="/healthchecker" component={HealthCheckPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <Route exact path="/*localLogout*" component={LogoutPage} />
              <Route exact path="/unauthorized" component={commonRoute(UnauthorizedPage)} />
              <Route exact path="/admin/viewcontent" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/course/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/viewcontent/folder/:fid/module/:mid" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/actionplans" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/rosters/:id" component={commonRoute(dynamicAdmin)} />
              <Route exact path="/admin/vc/fileDisplay/:fileId/:version" component={(vcFileDisplay)} />
              <Route exact path="/aempage" component={aemIframePage} />
              <Route exact path="/toucheventpage" component={touchEventPage} />

              <Route component={noMatch} />
            </Switch>}

          <Redirect
            strict
            from="/"
            to={requestedPath === "/" ? getUserDefaultUrl() : requestedPath}
          />

        </Suspense>
      </div>
    </BrowserRouter>
    </MuiThemeProvider>
  );
}

if (window.location.pathname.indexOf("unauthorized") === -1){
  App = securityFilter(App);
}
export default App;
