
import lrsRequestTemplate from "../../dao/lrsdata/lrsRequestTemplate.json"

export const getLRSHeaders = () => {
    const requestHeaders = {
        "Content-Type": "application/json",
        "x-Experience-API-Version": "1.0.3",
        "Authorization": `Basic ${window.ENV.REACT_APP_LRS_AUTHORIZATION_UI}`,
        "WM_SVC.NAME": "WBS-LEARNING-XAPI",
        "WM_SVC.ENV": window.ENV.REACT_APP_LRS_WM_SVC_ENV,
        "WM_CONSUMER.ID": window.ENV.REACT_APP_API_PROXY_CONSUMER_ID
    }
    return requestHeaders;
}

export const getLRSPostData = (lrsReqParams) => {
    if (lrsReqParams.scoreArr && Array.isArray(lrsReqParams.scoreArr)) {
        lrsReqParams.scoreArr.push(lrsReqParams.percentage)
    } else {
        lrsReqParams.scoreArr = []
    }
    const pageId = window.ENV.REACT_APP_LRS_PREFIX_URL + window.location.pathname;
    const currentDate = new Date().toISOString();
    const overallscore = Math.max(...lrsReqParams.scoreArr).toFixed(2)
    let lrsRequestTemplateStr = JSON.stringify(lrsRequestTemplate)
    lrsRequestTemplateStr = lrsRequestTemplateStr
    .replace('<<userWin>>', `${lrsReqParams.userWin}`)
    .replace('<<REACT_APP_LRS_WIN_HOMEPAGE>>', window.ENV.REACT_APP_LRS_WIN_HOMEPAGE)
    .replace('<<LRS_ID_URL>>', window.ENV.REACT_APP_LRS_ID_URL)
    .replace('<<courseLink>>', pageId)
    .replace('<<courseName>>', lrsReqParams.courseName)
    .replace('<<courseId>>', lrsReqParams.courseId)
    .replace('<<assessmentId>>', lrsReqParams.assessmentId)
    .replace('<<type>>', lrsReqParams.type)
    .replace('<<attemptnum>>', lrsReqParams.scoreArr && Array.isArray(lrsReqParams.scoreArr) ? lrsReqParams.scoreArr.length : 1)
    .replace('<<percentage>>', lrsReqParams.percentage)
    .replace('<<overallscore>>', overallscore)
    .replace('<<wmWeek>>', lrsReqParams.wmWeek)
    .replace('<<courseRoster>>', lrsReqParams.courseRoster)
    .replace('<<academyId>>', lrsReqParams.academyId)
    .replace('<<divisionId>>', lrsReqParams.divisionId)
    .replace('<<role>>', lrsReqParams.role)
    .replace('<<currentDate>>', currentDate)
    const lrsRequestTemplateJson = JSON.parse(lrsRequestTemplateStr)
    lrsRequestTemplateJson.result.score.scaled = Number(overallscore/100)
    lrsRequestTemplateJson.result.success = overallscore >= 80
    return lrsRequestTemplateJson
}