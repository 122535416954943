import { getLRSHeaders, getLRSPostData } from '../../utils/lrs';

export const postAssessmentDataToLRS = (lrsReqParams) => {
    const requestHeaders  =  getLRSHeaders();
    const inputData = getLRSPostData(lrsReqParams);
    const URL = `${window.ENV.REACT_APP_LRS_ENDPOINT_UI}statements`;
    const requestOptions = {
      method:"POST",
      headers : requestHeaders,
      body: JSON.stringify(inputData),
      redirect: "follow",
    }
    const promise = new Promise((resolve, reject) => {
      fetch(URL, requestOptions)
      .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(error));
      });
    return promise;
  
  }