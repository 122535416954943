import { callService } from '../../../utils/services/graphql';
import { localStorageConstants } from '../../../constants/LocalStorageConstants';
import { userRoleQuery } from '../../../constants/QueryConstants';
import { setItem } from '../../../utils/storage';

export const getUserRole = async (win) => {
    const userRoleRes = await callService(userRoleQuery.replace('<<win>>', win));
   
    if(userRoleRes) {
        setItem(localStorageConstants.DIVISION, userRoleRes.division_name|| "");
        setItem(localStorageConstants.DIVISION_ID, userRoleRes.division_id ||"")
        setItem(localStorageConstants.ROLE, userRoleRes.role|| "");
        setItem(localStorageConstants.ORIGINAL_ROLE, userRoleRes.role|| "");
        setItem(localStorageConstants.ACADEMY_ID, userRoleRes.academy_id||"");
        setItem(localStorageConstants.IS_VALID_USER, 'valid');
        setItem(localStorageConstants.FIRST_NAME, userRoleRes.first_name || "");
        setItem(localStorageConstants.LAST_NAME, userRoleRes.last_name || "");
}
    else{
        setItem(localStorageConstants.IS_VALID_USER, 'invalid');
    }
};


