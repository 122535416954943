import appInsightConstants from "../../constants/AppInsightsConstants";
import { localStorageConstants } from "../../constants/LocalStorageConstants";
import { setItem, getItem } from "../storage";
import * as moment from 'moment';
import { currentUTCtimestamp, getCurrentWmWeek } from "../common";
import { commonConstants } from '../../constants/CommonConstants';
import { getFirstName, getJobCode, getJobTitle, getLastName, getStoreNum, getUserId } from "../userprofile";
import * as appinsights from '../appinsights'
import pulseTelemetry  from '../pulseTelemetry'
var pagepath;

const PULSE_REPORTING_ID = `AcademyEDU-${window.ENV.REACT_APP_ENV}`;

export var setPageName = path => {

    switch (path) {
        case '/home':
            pagepath = 'home';
            break;
        case '/healthcheck':
            pagepath = 'healthcheck';
            break;
        case '/impersonateuser':
            pagepath = 'impersonateuser';
            break;
        case '/unauthorized':
            pagepath = 'unauthorizedPage';
            break;
        case '/facilitator/roster/:id':
            pagepath = 'facilitatorRosters';
            break;
        case '/aempage':
            pagepath = 'aempageIframePage';
            break;
        case '/course/:id':
            pagepath = 'CourseDetails';
            break;
        case '/facilitator/modules':
            pagepath = 'Module';
            break;
        case '/facilitator/modules/documents/file':
            pagepath = 'PDFfileDisplay';
            break;
        case '/toucheventpage':
            pagepath = 'toucheventpage';
            break;
        case '/facilitator/modules/documents':
            pagepath = 'Documents';
            break;
        case '/*localLogout*':
            pagepath = 'localLogout';
            break;
        case '/admin/managecontent':
            pagepath = 'manageContent';
            break;
        case '/admin/managecontent/course/:id':
            pagepath = 'adminDetailPage';
            break;
        case '/admin/managecontent/folder/:id':
            pagepath = 'adminFolderPage';
            break;
        case '/admin/managecontent/folder/:fid/module/:mid':
            pagepath = 'adminGuidesFilesPage';
            break;
        case '/admin/managecontent/detailpage/viewpage':
            pagepath = 'adminViewPage';
            break;
        case '/admin/managecontent/viewpage/affectedpage':
            pagepath = 'adminAffectedPage';
            break;
        case '/admin/viewcontent':
            pagepath = 'adminViewContent';
            break;
        case '/admin/viewcontent/course/:id':
            pagepath = 'adminViewDetail';
            break;
        case '/admin/viewcontent/folder/:id':
            pagepath = 'adminViewFolderPage';
            break;
        case '/admin/viewcontent/folder/:fid/module/:mid':
            pagepath = 'adminViewGuidesFiles';
            break;
        case '/admin/pendingreleases':
            pagepath = 'pendingReleases';
            break;
        case '/admin/rosters':
            pagepath = 'adminRostersPage';
            break;
        case '/admin/users':
            pagepath = 'adminUsersPage';
            break;
        case '/admin/rosters/:id':
            pagepath = 'adminRosterDetailsPage';
            break;
        case '/admin/fileDisplay/:fileId/:version':
            pagepath = 'adminMCFileDisplayPage';
            break;
        case '/admin/vc/fileDisplay/:fileId/:version':
            pagepath = 'adminVCFileDisplayPage';
            break;
        default:
            pagepath = 'error';
    }
};

export var setMappingKey = () => {
    const key = `edu-${pagepath}-${Math.random().toString(36).slice(2, 13)}`;

    if (!localStorage.getItem(localStorageConstants.SOURCEMAPPINGKEY)) {
        setItem(localStorageConstants.CURRENTMAPPINGKEY, key);
        setItem(localStorageConstants.SOURCEMAPPINGKEY, getItem(localStorageConstants.CURRENTMAPPINGKEY));
    } else {
        setItem(localStorageConstants.CURRENTMAPPINGKEY, key);
    }
};

export var pageViewEvent = () => {
    let pay;
    if (getItem(localStorageConstants.PAY_TYPE) === '1') {
        pay = 'Hourly'
    }
    else if (getItem(localStorageConstants.PAY_TYPE) === '2') {
        pay = 'Salaried'
    }
    var pageViewData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ACADEMYID]: getItem(localStorageConstants.ACADEMY_ID),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.SESSION_ID]: getItem(localStorageConstants.AUTH_CODE),
        [appInsightConstants.PAGE_NAME]: pagepath,
        [appInsightConstants.PAGE_URL]: window.location.href,
        [appInsightConstants.DIVISION]: getItem(localStorageConstants.DIVISION),
        [appInsightConstants.DIVISIONID]: getItem(localStorageConstants.DIVISION_ID),
        [appInsightConstants.SOURCEMAPPINGKEY]: getItem(localStorageConstants.SOURCEMAPPINGKEY),
        [appInsightConstants.CURRENTMAPPINGKEY]: getItem(localStorageConstants.CURRENTMAPPINGKEY),
        [appInsightConstants.PAYTYPE]: pay,
    };
    trackEvent(pagepath, 'pageView', pageViewData, 'pageViewEvent')
};


export var trackPreAssessmentButtonClickEvent = (rosterId, assessmentId, courseId) => {
    const eventName = "preAssessmentButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackTakeAssessmentButtonClickEvent = (rosterId, assessmentId, courseId) => {
    const eventName = "postAssessmentButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};


export var trackAttendanceButtonClickEvent = (rosterId, assessmentId, courseId) => {
    const eventName = "attendanceButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ATTENDANCE_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackSubmitAttendanceButtonClickEvent = (rosterId, assessmentId, courseId) => {
    const eventName = "submitAttendanceButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ATTENDANCE_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackAfterSubmitAssessmentButtonClickEvent = (academyId, rosterId, assessmentId, courseId, code, attemptNum) => {
    const eventName = "afterSubmitAssessmentButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.WM_WEEK]: getCurrentWmWeek(),
        [appInsightConstants.ACADEMY_ID]: academyId,
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.CODE]: code,
        [appInsightConstants.ATTEMPT_NUM]: attemptNum,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackBeforeSubmitAssessmentButtonClickEvent = (academyId, rosterId, assessmentId, courseId, questions, code) => {
    const eventName = "beforeSubmitAssessmentButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.WM_WEEK]: getCurrentWmWeek(),
        [appInsightConstants.ACADEMY_ID]: academyId,
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.QUESTIONS]: questions,
        [appInsightConstants.CODE]: code,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};
export var trackResumeAssessmentButtonClickEvent = (rosterId, assessmentId, courseId) => {

    const eventName = "resumeAssessmentButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackPauseAssessmentButtonClickEvent = (rosterId, assessmentId, courseId) => {

    const eventName = "pauseAssessmentButtonClick";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackValidationSubmitButtonClickEvent = (assessmentCodeEntered, rosterId, courseId, assessmentId) => {
    const eventName = "assessmentCodeInputEvent";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.ASSESSMENT_CODE_ENTERED]: assessmentCodeEntered,
        [appInsightConstants.ROSTER_ID]: rosterId,
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export var trackAssessmentAnswerClick = (assessmentId, roster, courseId, questionId, answer, code) => {
    const eventName = "assessmentAnswerEvent";
    var eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ASSESSMENT_ID]: assessmentId,
        [appInsightConstants.ROSTER_ID]: roster,
        [appInsightConstants.COURSE_ID]: courseId,
        [appInsightConstants.QUESTION_ID]: questionId,
        [appInsightConstants.ANSWER]: answer,
        [appInsightConstants.CODE]: code,
        [appInsightConstants.TIMESTAMP]: moment().format(commonConstants.YMMDD_HHMMSS),
    };
    trackEvent(commonConstants.ASSESSMENT_PAGE, commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export const setUserLoginData = () => {
    let pay;
    if (getItem(localStorageConstants.PAY_TYPE) === '1') {
        pay = 'Hourly'
    }
    else if (getItem(localStorageConstants.PAY_TYPE) === '2') {
        pay = 'Salaried'
    }
    const eventName = "login";
    const eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.ACADEMYID]: getItem(localStorageConstants.ACADEMY_ID),
        [appInsightConstants.ROLE]: getItem(localStorageConstants.ROLE),
        [appInsightConstants.SESSION_ID]: getItem(localStorageConstants.AUTH_CODE),
        [appInsightConstants.PAGE_NAME]: pagepath,
        [appInsightConstants.PAGE_URL]: window.location.href,
        [appInsightConstants.DIVISION]: getItem(localStorageConstants.DIVISION),
        [appInsightConstants.DIVISIONID]: getItem(localStorageConstants.DIVISION_ID),
        [appInsightConstants.SOURCEMAPPINGKEY]: getItem(localStorageConstants.SOURCEMAPPINGKEY),
        [appInsightConstants.CURRENTMAPPINGKEY]: getItem(localStorageConstants.CURRENTMAPPINGKEY),
        [appInsightConstants.PAYTYPE]: pay,
        [appInsightConstants.SESSSION_START_TS]: currentUTCtimestamp(),
        [appInsightConstants.FIRST_NAME]: getFirstName(),
        [appInsightConstants.LAST_NAME]: getLastName(),
        [appInsightConstants.USER_ID]: getUserId(),
        [appInsightConstants.JOB_CODE]: getJobCode(),
        [appInsightConstants.JOB_TITLE]: getJobTitle(),
        [appInsightConstants.STORE_NBR]: getStoreNum()
    };

    trackEvent('loginPage', commonConstants.EVENT_ON_CLICK, eventData, eventName);
};

export const setUserLogoutData = () => {
    const eventName = 'logout';
    const eventData = {
        [appInsightConstants.WIN_NBR]: getItem(localStorageConstants.WIN),
        [appInsightConstants.SESSION_ID]: getItem(localStorageConstants.AUTH_CODE),
        [appInsightConstants.JOB_CODE]: getJobCode(),
        [appInsightConstants.SESSION_CLOSE_TS]: currentUTCtimestamp(),
        [appInsightConstants.CURRENTMAPPINGKEY]: getItem(localStorageConstants.CURRENTMAPPINGKEY),
    };
    trackEvent('logoutHeaderView', commonConstants.EVENT_ON_CLICK, eventData, eventName);
};


const trackEvent = (pulseTelemetryActionContext, pulseTelemetryActionName, eventData, eventName) => {
    if (!window.ENV.REACT_APP_USER_TELEMETRY_VENDORS || window.ENV.REACT_APP_USER_TELEMETRY_VENDORS.includes('APPINSIGHTS')){
        appinsights.send(eventName, eventData)
    }
    if (window.ENV.REACT_APP_USER_TELEMETRY_VENDORS && window.ENV.REACT_APP_USER_TELEMETRY_VENDORS.includes('PULSE')){
        pulseTelemetry.send(pulseTelemetryActionContext, eventName, eventData, PULSE_REPORTING_ID)
    }
};
