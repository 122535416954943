import { getItem, setItem, removeItem } from '../storage';
import { localStorageConstants } from '../../constants/LocalStorageConstants';

export const getJobCode = () => {
  const jobcode =
    getItem(localStorageConstants.LAWSON_JOBCODE) !== 'na' &&
      getItem(localStorageConstants.LAWSON_JOBCODE) !== ''
      ? getItem(localStorageConstants.LAWSON_JOBCODE)
      : getItem(localStorageConstants.JOB_CODE);
  return jobcode;
};

export const getWin = () => {
  return getItem(localStorageConstants.WIN);
};

export const getCurrentMappingKey = () => {
  return getItem(localStorageConstants.CURRENTMAPPINGKEY);
};

export const getSessionId = () => {
  return getItem(localStorageConstants.AUTH_CODE);
};

export const getStoreNum = () => {
  return getItem(localStorageConstants.STORE_NO);
};

export const getUserId = () => {
  return getItem(localStorageConstants.USER_ID);
};

export const getRole = () => {
  return getItem(localStorageConstants.ROLE);
};

export const getOriginalRole = () => {
  return getItem(localStorageConstants.ORIGINAL_ROLE);
};

export const isRoleImpersonated = () => {
  return localStorageConstants.ROLE && localStorageConstants.ORIGINAL_ROLE && localStorageConstants.ROLE !== localStorageConstants.ORIGINAL_ROLE;
};

export const getFirstName = () => {
  return getItem(localStorageConstants.FIRST_NAME);
};

export const getLastName = () => {
  return getItem(localStorageConstants.LAST_NAME);
};

export const getUserLogin = () => {
  return getItem(localStorageConstants.USER_LOGIN);
};

export const getRequestedPath = () => {
  return getItem(localStorageConstants.REQUESTED_PATH);
};

export const getJobTitle = () => {
  return getItem(localStorageConstants.JOB_TITLE);
};

export const getLoginTime = () => {
  return getItem(localStorageConstants.LOGIN_TIME);
};

export const getPayType = () => {
  return parseInt(getItem(localStorageConstants.PAY_TYPE), 10);
};

export const getDivisionId = () => {
  return getItem(localStorageConstants.DIVISION_ID);
}

export const getDivisionName = () => {
  return getItem(localStorageConstants.DIVISION);
}

export const getAcademyId = () => {
  return parseInt(getItem(localStorageConstants.ACADEMY_ID), 10);
}

export const getAssessmentInProgress = () => {
  return getItem(localStorageConstants.ASSESSMENT_IN_PROGRESS);
}

export const setAssessmentInProgress = (courseId) => {
  return setItem(localStorageConstants.ASSESSMENT_IN_PROGRESS, courseId);
}

export const removeAssessmentInProgress = () => {
  return removeItem(localStorageConstants.ASSESSMENT_IN_PROGRESS);
}

export const getCounterInProgress = () => {
  return getItem(localStorageConstants.COUNTER_IN_PROGRESS);
}

export const setCounterInProgress = (counter) => {
  return setItem(localStorageConstants.COUNTER_IN_PROGRESS, counter);
}

export const removeCounterInProgress = () => {
  return removeItem(localStorageConstants.COUNTER_IN_PROGRESS);
}

export const getTypeInProgress = () => {
  return getItem(localStorageConstants.TYPE_IN_PROGRESS);
}

export const setTypeInProgress = (type) => {
  return setItem(localStorageConstants.TYPE_IN_PROGRESS, type);
}

export const removeTypeInProgress = () => {
  return removeItem(localStorageConstants.TYPE_IN_PROGRESS);
}