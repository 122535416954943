import React from 'react';
import {
  CircularProgress,
  createStyles,
  makeStyles,
} from '../../lib/materialui';

const useStyles = makeStyles((theme) =>
  createStyles({
    ParentContainer: {
      backgroundColor: '#041f41',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
    },
    Container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 235,
      [theme.breakpoints.down('xs')]: {
        marginTop: 115,
      },
    },
    AppName: {
      color: '#fff',
      marginTop: 8,
      fontSize: 34,
      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },
      fontFamily: 'Bogle',
      fontWeight: 500,
    },
    logo: {
      // height: 150,
      // width: 150,
      fontSize: 20,
    },
  })
);

const style = {
  color: '#7ab8e4',
  marginTop: 48,
};

const LoadScreen = () => {
  const classes = useStyles();
  return (
    <div data-testid="eduapp-initial-load-container" className={classes.ParentContainer}>
      <section data-testid="eduapp-initial-load-section" className={classes.Container}>
        <div data-testid="eduapp-initial-load-img-container" className={classes.logo}>
          <img
            src={require('../../assets/images/loadScreenIcon.png')}
            className={classes.logo}
            alt=""
          />
        </div>
        <div data-testid="eduapp-initial-load-appname" className={classes.AppName}>
          <span> </span>
        </div>
        <div data-testid="eduapp-initial-load-progress-container">
          <CircularProgress style={style} />
        </div>
      </section>
    </div>
  );
};
export default LoadScreen;
