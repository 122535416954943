import { callService } from '../../utils/services/graphql';
import { courseDetails, facilitatorRoster, UserCoursesHistoryMutation,GetSessionToken, MARK_COURSE_FAVORITE } from '../../constants/QueryConstants';
import { getWin } from '../../utils/userprofile';

const courseIdReplace = '<<courseId>>';

export const markCourseFavorite = async (courseId, favorite) => {
    let query = MARK_COURSE_FAVORITE;
    const win = getWin();
    query = query.replace(new RegExp(courseIdReplace, 'g'), courseId);
    query = query.replace('<<favorite>>', favorite);
    query= query.replace('<<win>>', win)
    return await callService(query);
};
export const getCourseDetails = async (courseId,rosterId) => {
    let query = courseDetails;
    const win = getWin();
    query = query.replace(new RegExp('<<id>>', 'g'), courseId);
    query = query.replace('<<courseRoster>>', rosterId);
    query= query.replace('<<win>>', win)
    return await callService(query);
};

export const getSessionToken = async(assessmentId)=>{
    let query = GetSessionToken;
    query= query.replace('<<assessmentId>>', assessmentId)
    return await callService(query);
}

export const getFacilitatorRoster = async (courseId, academyId) => {
    let query = facilitatorRoster;
    query = query.replace(courseIdReplace, courseId).replace('<<academyId>>', academyId);
    return await callService(query);
};

export const upsertUserCrsHistory = async (courseId) => {
    let query = UserCoursesHistoryMutation;
    const win = getWin();
    query = query.replace('<<win>>', win).replace(courseIdReplace, courseId);
    return await callService(query);
};