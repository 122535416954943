export const mutationQueryMapper = {
  updateRoster: "getRosters",
  submitManualScore: "getRosters",
  resetScore: "getRosters,getActionPlan",
  sendActionPlan: "getActionPlan",
  publishFile: "getQueue",
  publishFiles: "getQueue",
  userCreate: "getUser",
  deleteUser: "getUser",
  contentCommentUpdate: "contentCommentsByFileId,getActionPlanQuery",
  contentCommentCreate: "contentCommentsByFileId",
  contentCommentDelete: "contentCommentsByFileId",
  createCourse: "getFolder,foldersByParentId,courseWinValidation,getAllCourses,getDivisions",
  updateCourse:
    "getFolder,foldersByParentId,courseWinValidation,getAllCourses,getDivisions",
  updateAssessmentAnswers: "assessmentAnswers",
  participantPerformanceSubmit: "validateAssessment,checkAssessmentCode",
  upsertUserLoginHistory: "getUserLoginHistory",
  invalidateQuestion: "viewAssessment",
  createFolder: "getFolder,foldersByParentId,getAllCourses,getDivisions",
  updateFolder: "getFolder,foldersByParentId,getAllCourses,getDivisions",
  createHighlight: "highlights",
  highlightCreate: "highlights",
  highlightDelete: "highlights"
};
