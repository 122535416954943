import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button, WarningIcon
} from '../../../lib/materialui';
import cssStyles from "../../../assets/styles/scss/assessmentresultspopup.module.scss";

const { grpHdr, para, warningContainer, warningIcon, warningMessage } = cssStyles;

const ResumeAssmtDialogBox = ({ openDialog, closeDialog, assmtLanguage }) => {

  return <>
    <Dialog id="resumeAssmt-dialog" open={openDialog} onClose={() => closeDialog()}>
      <DialogTitle id="resumeAssmt-Course" >
        <div className={grpHdr}>Resume Assessment</div>
      </DialogTitle>
      <DialogContent id="resumeAssmt-dialog-content">
        <DialogContentText id="resumeAssmt-Course-description" className={para}>
          {`Please click 'Resume' to continue the in ${assmtLanguage}. Click 'Switch Language' to complete the assessment in a different language.`}
          <div className={warningContainer}>
            <WarningIcon className={warningIcon} />
            <div className={warningMessage}>If you switch languages, your current assessment progress will be reset and cannot be recovered.</div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions id="resumeAssmt-dialog-actions">
        <Button id="resumeAssmt-dialog-action-button" variant="contained" color="primary" onClick={() => closeDialog('resume')}>
          Resume
        </Button>

        <Button id="resumeAssmt-dialog-action-button" variant="contained" color="primary" onClick={() => closeDialog('switch')}>
          Switch Language
        </Button>
      </DialogActions>
    </Dialog>
  </>

}

export default ResumeAssmtDialogBox;
