import { getItem } from "../storage";
import { localStorageConstants } from "../../constants/LocalStorageConstants";
import { commonConstants } from "../../constants/CommonConstants";

export const getHeaders = async () => {

    var headers = {}
    headers = { "Content-Type": "application/json", "win": getItem(localStorageConstants.WIN), "role": getItem(localStorageConstants.ROLE) };
    handleImpersonatedRole(headers)
    return headers;
}

/**
 * A super admin can impersonate to other roles, in which case ROLE is set to the currently impersonated role and the original role is persisted in ORIGINAL_ROLE
 * If such a scenario exists, send role as super admin and impersonatedRole as the current role to the service calls
 * @param {*} headers 
 */
const handleImpersonatedRole = (headers) => {
    if(commonConstants.SUPER_ADMIN === getItem(localStorageConstants.ORIGINAL_ROLE) && getItem(localStorageConstants.ROLE) !== getItem(localStorageConstants.ORIGINAL_ROLE)){
        headers["role"] = commonConstants.SUPER_ADMIN;
        headers["impersonatedRole"] = getItem(localStorageConstants.ROLE);
    }
}

export const getServiceDomain = async (type) => {

    let domain = window.ENV.REACT_APP_SERVICES_DOMAIN;
    switch (type) {
        case "common": {
            domain = getItem("commonservicesdomain") || window.ENV.REACT_APP_COMMON_SERVICES_DOMAIN;
        }
            break;
        case "app": {
            domain = getItem("appservicesdomain") || window.ENV.REACT_APP_SERVICES_DOMAIN;
        }
            break;
    }
    return await domain;
}