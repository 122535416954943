import { callService } from "../../../utils/services/rest";

export async function auth(params, Type, methodType = "axiosPost") {
  let apiPath;
  switch (Type) {
    case "signUp":
      apiPath = "/iam/createUser-service";
      break;
    case "activateUserAccount":
      apiPath = "/iam/activateAccount-service";
      break;
    case "resendActivationLink":
      apiPath = "/iam/resendActivation-service";
      break;
    case "login":
      apiPath = "/iam/auth-service";
      break;
    case "register":
      apiPath = "/external-service-proxy/externaluser-services/register";
      break;
    case "activateAccount":
      /* With the recent chages in common servides this end point is converted to GET CALL,
      making temporary changes to ocnvert body parmas to query params. Ideally this endpoint should be a POST call. */
      const queryParamString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      apiPath = `/external-service-proxy/externaluser-services/activate?${queryParamString}`;
      break;
    case "ResetPassword":
      apiPath =
        "/external-service-proxy/externaluser-services/sendpasswordresetemail";
      break;
    case "ResetPasswordSubmit":
      apiPath = "/external-service-proxy/externaluser-services/resetpassword";
      break;
    case "GenerateToken":
      apiPath = "/external-service-proxy/token-services/fetchtoken";
      break;
    case "resetLinkValidation":
      apiPath =
        "/external-service-proxy/externaluser-services/pwdResetLinkValidation";
      break;
    /* case "resendActivationLink":
      apiPath =
        "/external-service-proxy/externaluser-services/resendactivationemail";
      break; */
    case "forgotPassword":
      apiPath = "/iam/forgotPassword-service";
      break;
    case "resetPassword":
      apiPath = "/iam/changePassword-service";
      break;
    case "resetTokenValidation":
      apiPath = "/iam/changePassword-tokenValidation-service";
      break;
    case "logout":
      apiPath = "/iam/logout-service";
      break;
    default:
      apiPath = "";
  }
  return await callService(apiPath, params, "", methodType);
}
