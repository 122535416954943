import moment from 'moment';
import { getRole } from '../../utils/userprofile';
import { localStorageConstants } from '../../constants/LocalStorageConstants';
import {
  setItem, getItem, setSessionItem, getSessionItem, removeSessionItem
} from '../../utils/storage';
import { v4 } from 'uuid';
import {commonConstants} from '../../constants/CommonConstants';

export const getRequestURI = () => {
  return window.location.pathname.substring(1);
}

export const getProfileInitials = (firstname, lastname) => {
  const firstInitial =
    firstname && firstname !== null ? firstname.charAt(0) : "";
  const lastInitial = lastname && lastname !== null ? lastname.charAt(0) : "";
  return `${firstInitial}${lastInitial}`;
};

export const toCapitalize = s => {
  if (s) {
    s = s.toLowerCase();
    s = s.charAt(0).toUpperCase() + s.slice(1);
    return s;
  } else {
    return "";
  }
};

export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export const removeLeadingZeros = s => {
  if (s) {
    s = s.replace(s.match("^0+(?!$)"), "");
    return s;
  } else {
    return "na";
  }
};

export const formatStoreNumber = storenum => {
  let formatStoreNum = "" + storenum;
  while (formatStoreNum.length < 5) {
    formatStoreNum = "0" + formatStoreNum;
  }
  return formatStoreNum;
};

export const getParameterByName = (name, url) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2]);
}

export var currentUTCtimestamp = () => {
  return new Date(Date.now()).toUTCString();
}

export const getNextWkTimeMS = () => {
  const now = new Date();
  return now.setDate(now.getDate() + 7);
};

export const momentCalendar = (obj) => {
  return moment(obj).calendar({
    sameDay: '[Today] h:mm a',
    lastDay: '[Yesterday] h:mm a',
    lastWeek: commonConstants.MMDDYYYY,
    sameElse: commonConstants.MMDDYYYY
  })
};

export const momentCalendarForNote =(obj) =>{
  const ab=  moment(obj).valueOf()
  return moment(ab/1000).calendar({
    sameDay: '[Today] h:mm a',
    lastDay: '[Yesterday] h:mm a',
    lastWeek: commonConstants.MMDDYYYY,
    sameElse: commonConstants.MMDDYYYY
  })
}

export const getCurrentWmWeek = () => {
  return getWmWeekForDate(new Date());
};

export const getWmWeekForDate = (date) => {
  let firstDayOfYear = moment(`${moment(date).format("YYYY")}-02-01`);
  while (firstDayOfYear.weekday() !== 6) {
    firstDayOfYear.subtract(1, "day");
  }

  if (moment(date) < firstDayOfYear) {
    firstDayOfYear = moment(
      `${moment(date)
        .subtract(1, "year")
        .format("YYYY")}-02-01`
    );
    while (firstDayOfYear.weekday() !== 6) {
      firstDayOfYear.subtract(1, "day");
    }
  }

  const fye = (parseInt(firstDayOfYear.format("YY"), 10) + 1).toString();
  const wmWeek = (moment(date).diff(firstDayOfYear, "week") + 1).toString();

  return {
    readable: `FYE${fye} Week - ${wmWeek}`,
    fye,
    wmWeek
  };
}
export const getWeekList = () => {
  return [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
  ];

}

export const getFYEList = current => {
  const FYElist = [];
  for (let i = current; i > current - 6; i--) {
    FYElist.push(`FYE${i}`);
  }
  return FYElist;
}

export const isNull = id => {
  if (id && id !== null && id !== "" && id !== undefined) {
    return true
  }
  else {
    return false
  }
}

export const getAdminContentLink = () => {
  const role = getRole() || '';
  if (role === 'superadmin' || role === 'administrator' || role === 'content_manager') {
    return '/admin/managecontent';
  }
  else if (role === 'coordinator' || role === 'operations_manager') {
    return '/admin/viewcontent';
  }
}

export const getUserDefaultUrl = () => {
  const user = (getItem(localStorageConstants.IS_VALID_USER))
      if(user==='invalid'){
        return "/usernotfound"
      }
  const role = getRole() || '';
  if (role === "facilitator" || role === "participant") {
    return "/home";
  }
  else if (role === "administrator" || role === "superadmin") {
    return "/admin/managecontent";
  }
  else if (role === "content_manager" || role === 'operations_manager') {
    return "/admin/viewcontent";
  }
  else if (role === "coordinator") {
    return "/admin/rosters";
  }
  else {
    return "/login";
  }
}

export const healthandwellnessAmsUrl =window.ENV.REACT_APP_AMS_HEALTH_AND_WELLNESS_URL
export const ReactAuthorizationUrl =window.ENV.REACT_APP_AUTHENTICATION_URL

export const genAssmtSessionToken = () => {
  const assmtSessionToken = v4();
  setSessionItem(localStorageConstants.ASSESSMENT_SESSION_TOKEN, assmtSessionToken);
  return assmtSessionToken;
}

export const getAssmtSessionToken = () => {
  return getSessionItem(localStorageConstants.ASSESSMENT_SESSION_TOKEN);
}

export const removeAssmtSessionToken = () => {
  removeSessionItem(localStorageConstants.ASSESSMENT_SESSION_TOKEN);
}

export const getDivisionArrValues=(userDivisionName,dataRes)=>{
  let divisions =[];
  switch (userDivisionName) {
    case commonConstants.SC_S:
      divisions = dataRes.filter(data => ((data.name === commonConstants.SUPPLY_CHAIN) || (data.name === commonConstants.STORE))).map(ids => ids.id);
      break;
    case commonConstants.S_HO:
      divisions = dataRes.filter(data => ((data.name === commonConstants.HOME_OFFICE) || (data.name === commonConstants.STORE))).map(ids => ids.id);
      break;
    case commonConstants.EXT_HO:
      divisions = dataRes.filter(data => ((data.name === commonConstants.EXTERNAL) || (data.name === commonConstants.HOME_OFFICE))).map(ids => ids.id);
      break;
    case commonConstants.S_EXT:
      divisions = dataRes.filter(data => ((data.name === commonConstants.EXTERNAL) || (data.name === commonConstants.STORE))).map(ids => ids.id);
      break;
    case commonConstants.SC_EXT:
      divisions = dataRes.filter(data => ((data.name === commonConstants.EXTERNAL) || (data.name === commonConstants.SUPPLY_CHAIN))).map(ids => ids.id);
      break;
    case commonConstants.SC_HO:
      divisions = dataRes.filter(data => ((data.name === commonConstants.HOME_OFFICE) || (data.name === commonConstants.SUPPLY_CHAIN))).map(ids => ids.id);
      break;
    case commonConstants.SC_S_HO:
      divisions = dataRes.
        filter(data => ((data.name === commonConstants.HOME_OFFICE) || (data.name === commonConstants.SUPPLY_CHAIN) || (data.name === commonConstants.STORE))).
        map(ids => ids.id);
      break;
    case commonConstants.SC_S_EXT:
      divisions = dataRes.
        filter(data => ((data.name === commonConstants.EXTERNAL) || (data.name === commonConstants.SUPPLY_CHAIN) || (data.name === commonConstants.STORE))).
        map(ids => ids.id);
      break;
    case commonConstants.S_HO_EXT:
      divisions = dataRes.
        filter(data => ((data.name === commonConstants.HOME_OFFICE) || (data.name === commonConstants.EXTERNAL) || (data.name === commonConstants.STORE))).
        map(ids => ids.id);
      break;
    case commonConstants.SC_HO_EXT:
      divisions = dataRes.
        filter(data => ((data.name === commonConstants.SUPPLY_CHAIN) || (data.name === commonConstants.HOME_OFFICE) || (data.name === commonConstants.EXTERNAL))).
        map(ids => ids.id);
      break;
  }
  return divisions;
}

/**
 * downloads the data provided by dataFetchCallback in a file name and format specified
 * @param {*} dataFetchCallback  the callback function to download the data
 * @param {*} fileFormat the mime type of the file to be downloaded
 * @param {*} fileName  the name fo the file with extension to be downloaded
 */
export const downloadFile = async (dataFetchCallback, fileFormat, fileName) => {

  if(!dataFetchCallback || !fileFormat || !fileName){
    console.error("downloading failed due to missing mandatory parameter");
  }

  try {
    const downloadData = await dataFetchCallback(); 
    const linkSource = 'data:'+fileFormat+';base64,'+downloadData;
    const tempDownloadLink = document.createElement("a");
    tempDownloadLink.href = linkSource;
    tempDownloadLink.download = fileName;
    tempDownloadLink.click();
  }
  catch(err) {
    console.error("downloading failed due to: ", err);
  }
}

export const getErrorMessage = (err) => {
  return `Fetch error => ${err && err.response
      && err.response.data && err.response.data.errors
      && err.response.data.errors.map(val => val.message)}`;
}


export const validateRedirection = (queryParams) => {
  let isValidRedirection = true
  try {
    if (queryParams?.redirecturl) {
      const url = new URL(queryParams.redirecturl)
      const domain = url?.hostname?.replace('www.', '')
      if (domain) {
        const selectedIndex = window.ENV.REACT_APP_REDIRECTION_WHITELISTED_DOMAIN.indexOf(domain)
        if (selectedIndex === -1) {
          isValidRedirection = false
        }
      } else {
        isValidRedirection = false
      }
    }
  } catch (error) {
    isValidRedirection = false
    console.error('validateRedirection.error', error)
  }
  if (!isValidRedirection) {
    window.location.href = '/'
  }
}

export const getUrlPath = (url) =>{
  let path = ''
  try {
    const urlObj = new URL(url)
    path = urlObj?.pathname??''
  } catch (error) {
    console.error('getUrlPath.error', error)
  }
  return path
}

/**
 * Replace ipad curly single quote character with single quote character from input string
 * @param {*} inputStr input string
 */
export const replaceIpadChars = (inputStr) => {
  return inputStr ? inputStr.replace("’", "'") : ''
}

export const getDivName = (divInfo) => {
  let div = null;
  if (divInfo.includes("&")) {
    switch (divInfo) {
      case 'SC&S':
        div = 'Supply Chain, Store';
        break;
      case 'S&HO':
        div = 'Home Office, Store';
        break;
      case 'S&EXT':
        div = 'Externalized, Store';
        break;
      case 'SC&HO':
        div = 'Home Office, Supply Chain';
        break;
      case 'SC&EXT':
        div = 'Externalized, Supply Chain';
        break;
      case 'EXT&HO':
        div = 'Externalized, Home Office';
        break;
      case 'SC&S&HO':
        div = 'Home Office, Supply Chain, Store';
        break;
      case 'SC&S&EXT':
        div = 'Externalized, Supply Chain, Store';
        break;
      case 'S&HO&EXT':
        div = 'Externalized, Home Office, Store';
        break;
      case 'SC&HO&EXT':
        div = 'Externalized, Home Office, Supply Chain';
        break;
    }
  }
  else {
    div = divInfo;
  }
  return div;
}


export const SSOlogin = () => {
  // TODO: remove the log in next release.
  console.log('SSOlogin', 'sso login redirection')
  window.location.href = `${window.ENV.REACT_APP_IAM_AUTHENTICATION_URL}`;
}

export const saveSurveyDetails = async (courseId) => {
  await new Promise(() => setTimeout(function () {
    let surveyClickedList = getItem("surveyList");
    if (surveyClickedList) {
      surveyClickedList = JSON.parse(surveyClickedList);
    } else {
      surveyClickedList = [];
    }
    surveyClickedList.push(courseId);
    setItem("surveyList", JSON.stringify(surveyClickedList));
  }, 60 * 1000));
}
