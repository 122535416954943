export const mutationNamesList = [
  "updateRoster",
  "submitManualScore",
  "resetScore",
  "sendActionPlan",
  "publishFile",
  "publishFiles",
  "userCreate",
  "contentCommentUpdate",
  "contentCommentUpdate",
  "contentCommentCreate",
  "contentCommentDelete",
  "updateCourse",
  "updateAssessmentAnswers",
  "participantPerformanceSubmit",
  "upsertUserLoginHistory",
  "invalidateQuestion",
  "createFolder",
  "updateFolder",
  "updateFolderOrder"  
];

export const quriesExcludedToBeCached = [
  "getFolder",
  "me",
  "highlights",
  "contentCommentByFileId",
  "assessmentByCourseIdType",
  "checkAssessmentCode",
  "assessmentAnswers",
  "getRosters",
  "getSessionToken",
  "validateAssessment"
];

export const facOrPartquriesExcludedToBeCached = [
  "highlights",
  "getSessionToken",
  "contentCommentByFileId",
  "assessmentByCourseIdType",
  "checkAssessmentCode",
  "assessmentAnswers",
  "getRosters",
  "validateAssessment"
];
