import { createStyles, makeStyles } from '../../lib/materialui';
import { colorCodes } from '../../constants/ColorConstants';

export const DataLoaderStyles = makeStyles(theme =>
  createStyles({
    ParentContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "35vh",
      width: "100%"
    },
    Container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    LoadText: {
      color: "#017acd",
      marginTop: 5,
      fontSize: 14,
      fontFamily: "Bogle",
      fontWeight: 500
    },
    logo: {
      color: '#7ab8e4',
      borderRadius: '50%',
      width: 60,
      height: 60,
      marginBottom: 14.5,
    },
    ChangeLogItem: {
      marginBottom: '5',
      "& a": {
        cursor: 'pointer',
      }
    },
    ChangeLogContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10
    }
  })
);

export const LoadingStyles = {
  text: {
    color: "#041F41",
    fontSize: 20,
    fontWeight: "bold"
  },
  container: {
    textAlign: "center",
    padding: 20
  }
};

export const LoginStyles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: "24px",
    marginRight: "24px",
    [theme.breakpoints.up(400)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: "50px",
    display: 'flex',
    alignItems: 'center',
    padding: "24px",
  },
  avatar: {
    margin: "8px",
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: "8px",
  },
  submit: {
    marginTop: "24px",
  },
  error: {
    color: 'red',
    textAlign: 'center'
  }

});

export const headerStyles = {
  insideMobile: {
    display: 'flex',
    '@media (min-width:0px) and (max-width:1024px)': {
      display: 'none!important'
    }
  },
  bcLink: {
    display: 'flex',
    flexDirection: 'row',
    color: '#fff',
    fontFamily: 'Bogle',
    fontSize: 18,
    fontWeight: 'normal',
    textDecoration: 'none',
    '&:hover': {
      color: '#fff',
      textDecoration: 'none'
    }
  },
  arrowIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    marginRight: 5
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  logoContainerCustom: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    height: '100%',
    gridGap:'25px'
  },
  mobileBack: {
    display: 'none',

    '@media (min-width:0px) and (max-width:1024px)': {
      display: 'flex',
      color: '#fff',
      fontFamily: 'Bogle',
      fontWeight: 'bold',
      fontSize: 20,
    }
  },
  mobMenuPdfPage: {
      position: 'absolute',
      backgroundColor: '#fff',
      border: colorCodes.SOLID_BORDER_2,
      boxShadow: colorCodes.BOX_SHADOW_1_1_4_0,
      top: '6.5%',
      right: '1.2rem',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
  },
  mobMenuItmPdfPage: {
    padding: '5px 5px',
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Bogle',
    fontSize: 40,
    width: 'auto',
    '&:not(:last-child)': {
      borderBottom: colorCodes.BORDER_BOTTOM_1
    },
    menuOff: {
      height: '68px'
    },
  },
  menuPdfPage: {
      position: 'absolute',
      backgroundColor: '#fff',
      border: colorCodes.SOLID_BORDER_2,
      boxShadow: colorCodes.BOX_SHADOW_1_1_4_0,
      top: '4.4rem',
      right: '1rem',
      zIndex: 1201,
      display: 'flex',
      flexDirection: 'column'
  },
  menuItmPdfPage: {
    padding: '5px 5px',
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Bogle',
    fontSize: 20,
    width: 'auto',
    '&:not(:last-child)': {
      borderBottom: colorCodes.BORDER_BOTTOM_1
    },
    menuOff: {
      height: '68px'
    },
  },
  mobMenu: {
    display: 'none',
    '@media (min-width:0px) and (max-width:640px)': {
      backgroundColor: '#fff',
      border: colorCodes.SOLID_BORDER_2,
      boxShadow: colorCodes.BOX_SHADOW_1_1_4_0,
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      right: '15px'
    }
  },
  mobMenuItm: {
    padding: '5px 5px',
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Bogle',
    fontSize: 16,
    width: 'auto',
    '&:not(:last-child)': {
      borderBottom: colorCodes.BORDER_BOTTOM_1
    },
    menuOff: {
      height: '68px'
    },
  },
  bell: {
    height: 30
  },
  username: {
    fontFamily: 'Bogle',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
    '@media (min-width:0px) and (max-width:640px)': {
      display: 'none',
    }
  },
  mobLogout: {
    display: 'none',
    '@media (min-width:0px) and (max-width:640px)': {
      display: 'flex',
      alignSelf: 'flex-end'
    }
  },
  mobLogoutMain: {
    display: 'none',
    '@media (min-width:0px) and (max-width:640px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  logout: {
    cursor: 'pointer',
    fontFamily: 'Bogle',
    fontSize: 20,
    color: '#fff',
    '@media (min-width:0px) and (max-width:640px)': {
      display: 'none',
    }
  },
  noteHeight:{
    height: '100%'
  },
  viewNotesStyle: {
    cursor: 'pointer',
    fontFamily: 'Bogle',
    fontSize: '18px',
    color: '#fff'
  },
  acadText: {
    fontFamily: 'Bogle',
    fontSize: 20,
    color: '#fff',
  },
  swapParticipantText: {
    fontFamily: 'Bogle',
    fontSize: '18px',
    color: '#fff',
    marginLeft: '38px',
    cursor: 'pointer',
    marginTop: '5px'
  },
  swapParticipantTextTab: {
    fontFamily: 'Bogle',
    fontSize: '18px',
    color: '#fff',
  },
  customExitText: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center'
  },
  customExitTextMobile: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: '1.5em',
    color: '#fff',
    display: 'flex',
    alignItems: 'center'
  },
  customExitTextAemMobile: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: '8px',
    color: '#fff',
    marginTop: 'auto',
    marginLeft: '6px',
    display: 'flex',
    alignItems: 'center'
  },
  customSwapTextMobile: {
    fontFamily: 'Bogle',
    fontSize: '2em',
    color: '#fff',
    marginLeft: '24px',
    marginBottom: '25px',
    display: 'flex',
    alignItems: 'center'
  },
  customSwapTextMobileAem: {
    fontFamily: 'Bogle',
    fontSize: '8px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  customBackText: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px',
    marginLeft: '10px'
  },
  customBackTextMob: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px',
    marginLeft: '20px',
    marginRight:"158px"
  },
  customBackTextMobAem: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginLeft: '10px'
  },
  verticalLign: {
    borderRight: colorCodes.SOLID_BORDER_CODE,
    height: '37px'
  },
  verticalLignTablet: {
    borderRight: 'solid 2px #ffffff',
    height: '37px'
  },
  verticalLignMobile: {
    borderRight: 'solid 6px #ffffff',
    height: '90%'
  },
  verticalLignMobileAem: {
    borderRight: 'solid 2px #ffffff',
    height: '100%'
  },
  eduText: {
    fontFamily: 'Bogle',
    fontSize: 25,
    fontWeight: 'bold',
    color: '#e0b607'
  },
  headerDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: 70,
    padding:'2rem'
  },
  CustomHeaderDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: 70,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
    padding:'10px'
  },
  CustomAemHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: 70,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
    top:0,
    left:0,
    padding:'10px',
    justifyContent: colorCodes.SPACE_BETWEEN,
  },
  CustomAemHeaderTab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: 53,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
    top:'0px',
    left:0,
    padding:'10px'
  },
  CustomHeaderMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: 111,
    position: 'fixed',
    width: '100%',
    zIndex: 901,
    padding:'20px'
  },
  CustomAemHeaderMobile: {
    top:'0px',
    left:0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: '53px',
    position: 'fixed',
    width: '100%',
    zIndex: 901,
    padding:'10px'
  },
  headerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    backgroundColor: '#0071ce',
    height: 70
  },
  arrowHeaderIcon: {
    marginTop: '3px'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexRowItem:{
    display: 'flex',
    flexDirection: 'row',
    height:'100%',
    alignItems: 'center',
    gridGap: '10px',
    justifyContent: 'flex-end',
    marginRight:'2rem'
  },
  flexRowHeader: {
    display: 'flex',
    flexDirection: 'row',
    height:'100%',
    alignItems:'center',
    gridGap: '10px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gridGap:'1rem'
  },
  customFlexColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    height: 45,
    '@media (min-width: 0px) and (max-width: 640px)': {
    },
  },
  logoCustom: {
    height: 45,

  },
  avatarStyle: {
    color: '#061f41',
    width: '25px',
    height: '25px',
    fontSize: '12px',
  },
  aemAvatarStyle: {
    color: '#061f41',
    width: '25px',
    height: '25px',
    fontSize: '12px',
    margin: '25px 21px 21px 0',
    '@media (min-width: 0px) and (max-width: 530px)': {
      margin: '33px 21px 21px 0',
    }
  },
  avatarStyleMobile: {
    color: '#061f41',
    backgroundColor: '#d8d8d8',
    width: '2em',
    height: '2em',
    fontSize: '2.2em'
  },
  avatarStyleMobileAem: {
    color: '#061f41',
    backgroundColor: '#d8d8d8',
    fontSize: '12px',
    height: '25px',
    width: '25px',
    marginRight: '16px'
  },
  demoMessage: {
    background: '#ffffff',
    textAlign: colorCodes.TEXT_ALIGN_CENTER_IMP,
    fontFamily: 'Bogle',
    fontSize: '18px',
    justifyContent: colorCodes.SPACE_BETWEEN,
    marginTop: '3px',
    padding: colorCodes.PADDING_20_10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: 1,
    zIndex: 2
  },
  exitContainerMob: {
    // marginTop: '30px'
  },
  exitContainerAemMob: {
    marginTop: '50px'
  },
  closeIcon: {
    height: '2em',
    width: '2em',
    color: '#ffff',
    marginLeft: '1.8rem'
  },
  arrowBackMob: {
    height: '4em',
    width: '4em',
    color: '#ffff'
  },
  closeIconAem: {
    color: '#ffff',
    margin: '0 14px 0 14.5px'
  },
  arrowBackMobAem: {
    color: '#ffff'
  },
  backTextMob: {
    fontSize: '2em',
    marginLeft: 'inherit'
  },
  backTextMobAem: {
    fontSize: '8px',
  },
  swapPartMobContainer: {
    marginTop: '36px',
    marginLeft: '30px'
  },
  swapPartMobContainerAem: {
    // marginTop: '15px',
    // marginLeft: '30px'
  },
  swapPartMobIcon: {
    height: '5em',
    width: '5em',
    color: '#ffff',
    marginLeft: '25%'
  },
  swapPartMobIconAem: {
    color: '#ffff',
    marginLeft: '25%'
  },
  viewNotesContainer: {
    marginTop: '70px',
    marginLeft: '30px'
  },
  viewNotesIconContainer: {
    display: 'flex'
  },
  viewNotesIcon: {
    height: '3em',
    width: '3em',
    color: '#ffff',
    marginLeft: '10%'
  },
  viewNotesTextMob: {
    fontSize: '3em',
    color: '#fff',
    fontFamily: 'Bogle'
  }
};

export const homeStyles = {
  loaderStyle: {
    color: '#0071ce'
  },
  page: {
    padding: '0 35px 40px 35px',
    '@media (min-width: 0px) and (max-width: 640px)': {
      padding: '0 10px 40px 10px',
    },
  },
  navPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    marginTop: 22,
    margin: colorCodes.MARGIN_22_0_0_1,
    '@media (min-width: 0px) and (max-width: 360px)': {
      justifyContent: 'center'
    },
    '@media (min-width: 361px) and (max-width: 640px)': {
      margin: colorCodes.MARGIN_22_0_0_1,
    },
  },
  noCarouselnavPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    marginTop: 72,
    margin: '70px 0px 0px 1px',
    '@media (min-width: 0px) and (max-width: 360px)': {
      justifyContent: 'center'
    },
  },
  demoMessage: {
    background: 'red',
    color: 'white',
    textAlign: colorCodes.TEXT_ALIGN_CENTER_IMP,
    fontFamily: 'Bogle',
    fontSize: '18px',
    justifyContent: colorCodes.SPACE_BETWEEN,
    marginTop: '3px',
    padding: colorCodes.PADDING_20_10,
    position: 'absolute',
    left: 0,
    width: '100%',
    opacity: 1,
    zIndex: 2
  },
  demoMobile: {
    marginLeft: '30%',
    marginTop: '40px',
    justifyContent: 'center',
    '@media (min-width: 568px)': {
      display: 'none'
    },
  }
};

export const carouselStyles = {

  mobDemoBtn: {
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
    '@media (min-width: 0px) and (max-width: 360px)': {
      display: 'flex',
    },
  },
  mobCarouselNav: {
    display: 'none',
    '@media (min-width: 0px) and (max-width: 567px)': {
      display: 'flex',
      flexDirection: 'row',
      padding: '7px 0px 0 11px',
      marginTop: 4,
      justifyContent: 'flex-end'
    },
  },
  mobileNavPanel: {
    display: 'flex',
    flexDirection: 'column',
  },
  courseSection: {
    padding: '23px 0 0 0',
    '@media (min-width: 0px) and (max-width: 640px)': {
      padding: '7px 0px 0 11px',
    },
  },
  demoCourseSection: {
    padding: '70px 0 0 0',
    '@media (min-width: 0px) and (max-width: 640px)': {
      padding: '70px 0px 0 11px',
    },
  },
  disableNav: {
    backgroundColor: 'darkgray!important',
    cursor: 'default!important'
  },
  pagination: {
    fontFamily: 'Bogle',
    fontSize: '16px',
    color: '#000',
    marginRight: 15
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  arrowL: {
    position: 'relative',
    left: 4
  },
  circleNav: {
    borderRadius: '50%',
    padding: 5,
    margin: '0 5px',
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  carouselNavPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    alignItems: 'center',
    marginTop: 22,
    margin: colorCodes.MARGIN_22_0_0_1,
    '@media (min-width: 0px) and (max-width: 567px)': {
      display: 'none',
    },
  },
  demoCarouselNavPanel: {
    '@media (min-width: 0px) and (max-width: 567px)': {
      display: 'none',
    },
  },
  carousel: {
    display: 'flex',
    flexDirection: 'row',
    '& card': {
      border: 'solid 3px red!important '
    },
  },
  mycourses: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0px 0 0px',
    '@media (min-width: 0px) and (max-width: 640px)': {
      margin: '0 10px 0 0px',
    },
  },
  title: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 13
  }
};

export const dataGridStyles = {
  tableHeader: {
    display: 'none',
    '@media (min-width: 0px) and (max-width: 360px)': {
      fontFamily: 'Bogle',
      fontWeight: 'bold',
      fontSize: 18,
      margin: '0 0 3px 0px',
      display: 'block'
    },
  },
  allcourses: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 0 0 0',
    '@media (min-width: 0px) and (max-width: 640px)': {
      margin: '24px 0 0 0',
    },
  },
  gridHeader: {
    fontFamily: colorCodes.FONT_BOGLE_IMP,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: colorCodes.SPACE_BETWEEN,
    "& .MuiTextField-root": {
      '@media (min-width: 0px) and (max-width: 414px)': {
        width: '100%',
        paddingLeft: 0,
        flex: 'none'
      },
    },
    "& .MuiTableRow-root:empty": {
      display: 'none'
    },
    "&  .MuiTableSortLabel-icon": {
      color: "black!important",
      fontWeight: 'bold'
    },
    '& .MuiTableSortLabel-root': {
      '@media (min-width: 0px) and (max-width: 640px)': {
        fontSize: colorCodes.FONT_14_IMP
      },
      '@media (min-width: 641px) and (max-width: 1024px)': {
        fontSize: colorCodes.FONT_16_IMP
      },
    },
    '& .MuiTableRow-root': {
      '@media (min-width: 0px) and (max-width: 375px)': {
        fontSize: '12px!important'
      },
      '@media (min-width: 376px) and (max-width: 640px)': {
        fontSize: colorCodes.FONT_14_IMP
      },
      '@media (min-width: 641px) and (max-width: 1024px)': {
        fontSize: colorCodes.FONT_16_IMP
      },
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
    '& .MuiTablePagination-select': {
      fontFamily: 'Bogle',
    },
    '& .MuiTableCell-root': {
      fontFamily: colorCodes.FONT_BOGLE_IMP,
      '@media (min-width: 0) and (max-width: 375px)': {
        padding: '7px!important'
      },
    },
    '& .MuiTypography-caption': {
      fontFamily: 'Bogle',
    },
    '& .MuiInput-input': {
      fontFamily: 'Bogle',
    },
    '& .MuiTypography-h6': {
      fontFamily: colorCodes.FONT_BOGLE_IMP,
      fontSize: 24,
      fontWeight: 'bold'
    },
    '& .MuiToolbar-root': {
      backgroundColor: '#f7f7f7',
    },
    '& .MuiToolbar-gutters': {
      padding: '0 0px!important',
    },
    '& .MuiTableHead-root': {
      backgroundColor: '#000'
    },
    '@media (min-width: 0px) and (max-width: 640px)': {
      margin: '0px 0px 0 1px',
    },
    '@media (min-width: 0px) and (max-width: 414px)': {
      '& .MuiTypography-h6': {
        display: 'none'
      },
      '& [class^="MTableToolbar-spacer"]': {
        flex: 'none!important',
      },
    },
  },
};

export const dataGridTabelStyle = {
  width: '100%',
  fontFamily: colorCodes.FONT_BOGLE_IMP,
  fontWeight: 'bold!important'
};

export const dataGridTabelOptionStyle = {
  pageSizeOptions: [5, 10, 20, 30, 40, 50],
  headerStyle: {
    backgroundColor: '#f4f4f4',
    color: '#000',
    fontFamily: 'Bogle',
    fontSize: 18,
  },
  searchFieldStyle: {
    zIndex: 1
  },
  rowStyle: {
    backgroundColor: '#f4f4f4',
    color: '#000',
    fontFamily: colorCodes.FONT_BOGLE_IMP,
    fontSize: 16,
    '@media (minWidth:0px) and (maxWidth:1024px)': {
      fontSize: '12px!important',
    }
  }
};

export const arrowButtonStyles = {
  arrowIcon: {
    display: 'flex',
  },
  button: {
    border: 'solid 2px #17304f',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN,
    height: 25,
    alignItems: 'center',
    padding: '0 3px 0 9px'
  },
  buttonTxt: {
    fontFamily: 'Bogle',
    fontSize: 14,
    color: '#041f41'
  }
};

export const notesStyles = {
  list: {
    width: "277px",
    overflowX: "hidden",
    '@media (min-width:1000px) and (max-width:1024px) and (orientation:landscape)': {
      width: "277px",
      margin: "auto"
    },
    '@media (min-width: 320px) and (max-width:415px)': {
      width: "100%",
    },
  },
  tabletList: {
    width: "500px",
    height:"650px",
    margin: "auto"
  },
  aemtabletList:{
    width:"460px",
    height:"500px",
    margin:"auto"
  },
  mobileList:{
    width:"100%",
    overflowX:"hidden",
    marginTop:"15px"
  },
  enterNoteContainer:{
    zIndex:100,
    backgroundColor:"white",
    position:"fixed",
  },
  enterNoteContainerTablet:{
     zIndex:100,
    backgroundColor:"white",
    position:"fixed",
    width:"520px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  enterNoteContainerTabletAem:{
     zIndex:100,
    backgroundColor:"white",
    position:"fixed",
    width:"455px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  enterNoteContainerMobile:{
    zIndex:100,
    backgroundColor:"white",
    position:"fixed",
    width:"100%",
    top:"0px"
  },
  sortableHelper:{
    marginTop:"185px"
  },
  tabletsortableHelper:{
    marginTop:"225px"
  },
  tabletdragdrop:{
    fontSize:"30px"
  },
  dragdrop:{
    fontSize:"16px"
  },
  sortableHelperMobile:{
    marginTop:"650px"
  },
  icon: {
    color: "grey"
  },
  sorting: {
    zIndex: 3000
  },
  button: {
    padding: -10
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: "inherit"
  },
   buttonTextNotes: {
    cursor: 'pointer',
    fontFamily: 'Bogle',
    fontSize: '18px',
    color: '#fff',
    textTransform:"none",
    fontWeight: "bold",
  },
  listItem: {
  },
  textBoxItem: {
    width: "100%",
    color: "#000000",
    marginLeft: "4px",
    "& .MuiInputBase-root": {
      color: '#000000',
      fontFamily: 'Bogle',
      lineHeight: "normal"
    },
    "& . Mui-disabled": {
      color: "#000000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    }},
    tablettextBoxItem: {
      width: "100%",
      color: "#000000",
      marginLeft: "4px",
      "& .MuiInputBase-root": {
        color: '#000000',
        fontFamily: 'Bogle',
        lineHeight: "normal",
        fontSize:"30px"
      },
      "& . Mui-disabled": {
        color: "#000000"
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none"
      }},
  mobiletextBoxItem: {
    width: "100%",
    color: "#000000",
    margin: "30px",
      letterSpacing:"2.5px",
    "& .MuiInputBase-root": {
      color: '#000000',
      fontFamily:'Bogle',
      lineHeight:"normal",
      fontSize:"50px",
    },
    "& . Mui-disabled": {
      color: "#000000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    }
  },
  dialogSize: {
    width: "414px"
  },
  hideNote: {
    border: "none",
    width: "100%",
    backgroundColor: "#f7cf52",
    fontWeight: "bolder",
    fontSize: "inherit",
    padding: "1em",
    justifyContent: "left",
    display: "flex",
  },
  closeIcon: {
    textAlignLast: "right",
    color: "lightgray",
    cursor: "pointer",
    fontSize: "30px",
    "& .MuiSvgIcon-root": {
      fontSize: "30px"
    }
  },
  tabletcloseIcon: {
    textAlignLast: "right",
    color: "lightgray",
    cursor: "pointer",
    fontSize: "45px",
    "& .MuiSvgIcon-root": {
      fontSize: "45px"
    }
  },
  closeIconContainer:{
    position:"fixed",
    right:"10px"
  },
  dateContainer:{
    textAlign:"left",
    marginTop:"10px"
  },
  mobilecloseIcon:{
    textAlignLast:"right",
    color:"lightgray",
    cursor:"pointer",
    fontSize:"100px",
    marginRight:"30px",
    "& .MuiSvgIcon-root":{
      fontSize:"100px"
    }
  },
  enterNoteStyle:{
    marginLeft:"15px",
    marginRight:"48%",
    fontFamily:"Bogle",
    color:"#000000",
    fontSize:"12px",
    textAlign:"left",
    cursor:"default"
  },
  tabletenterNoteStyle:{
    marginLeft:"15px",
    fontFamily:"Bogle",
    color:"#000000",
    fontSize:"30px",
    textAlign:"left",
    cursor:"default"
  },
  aementerNoteStyle:{
    marginRight:"48%",
    fontFamily:"Bogle",
    color:"#000000",
    fontSize:"12px",
    textAlign:"left",
    cursor:"default",
    marginLeft:"15px"
  },
  aemmobileenterNoteStyle:{
    marginRight:"60%",
    marginLeft:"12px",
    fontFamily:"Bogle",
    color:"#000000",
    fontSize:"12px",
    textAlign:"left",
    cursor:"default"
  },
  aemtabletenterNoteStyle:{
    marginLeft:"21px",
    fontFamily:"Bogle",
    color:"#000000",
    fontSize:"12px",
    textAlign:"left",
    cursor:"default",
    display:"block",
  },
  mobileenterNoteStyle:{
    marginLeft:"35px",
    fontFamily:"Bogle",
    color:"#000000",
    fontSize:"30px",
    textAlign:"left",
    cursor:"default",
    marginTop:"10px",
    paddingTop:"50px",
    marginBottom:"10px"
  },
  sortable:{
    cursor:"default!important"
  },
  textAreaStyle:{
    height:"84px",
    width:"88%",
    marginLeft:"12px",
    marginRight:"8px",
    border: colorCodes.SOLID_BORDER_GRAY,
    resize:"none",
    fontFamily:"Bogle",
    fontSize:"16px"
  },
  tablettextAreaStyle:{
    height:"100px",
    width:"88%",
    marginLeft:"12px",
    marginRight:"8px",
    border: colorCodes.SOLID_BORDER_GRAY,
    resize:"none",
    fontFamily:"Bogle",
    fontSize:"30px"
  },
  aemtextAreaStyle:{
    height:"84px",
    width:"88%",
    marginLeft:"12px",
    marginRight:"8px",
    border: colorCodes.SOLID_BORDER_GRAY,
    resize:"none",
    fontFamily:"Bogle",
    fontSize:"16px"
  },
  mobiletextAreaStyle:{
    height:"350px",
    width:"93%",
    margin:"20px 40px",
    border: colorCodes.SOLID_BORDER_GRAY,
    resize:"none",
    fontFamily:"Bogle",
    fontSize:"45px"
  },
  aemtablettextAreaStyle:{
    height:"84px",
    width:"95%",
    border: colorCodes.SOLID_BORDER_GRAY,
    resize:"none",
    fontFamily:"Bogle",
    marginLeft:"15px",
    fontSize:"16px"
  },
  aemmobiletextAreaStyle:{
    height:"84px",
    width:"93%",
    border: colorCodes.SOLID_BORDER_GRAY,
    resize:"none",
    fontFamily:"Bogle",
    marginRight:"10px",
    marginLeft:"10px" ,
    fontSize:"16px"
  },
  notesDelete: {
  border: "none",
  backgroundColor: "transparent",
  color:"red",
  textAlign: "right",
  margin:"8px",
  fontFamily:"Bogle",
  fontSize:"14px",
  cursor:"pointer"
},
tabletnotesDelete: {
  border: "none",
  backgroundColor: "transparent",
  color:"red",
  textAlign: "right",
  margin:"8px",
  fontFamily:"Bogle",
  fontSize:"24px",
  cursor:"pointer"
},
notesEdit :{
  border: "none",
  backgroundColor: "transparent",
   color:"#041f41",
  textAlign: "right",
  margin:"8px",
  fontFamily:"Bogle",
  fontSize:"14px",
  cursor:"pointer"
},
tabletnotesEdit:{
  border: "none",
  backgroundColor: "transparent",
   color:"#041f41",
  textAlign: "right",
  margin:"8px",
  fontFamily:"Bogle",
  fontSize:"24px",
  cursor:"pointer"
},
mobilenotesDelete: {
  border: "none",
  backgroundColor: "transparent",
  color:"red",
  textAlign: "right",
  margin:"0px 40px",
  fontFamily:"Bogle",
  fontSize:"40px",
  cursor:"pointer"
},
mobilenotesEdit :{
  border: "none",
  backgroundColor: "transparent",
   color:"#041f41",
  textAlign: "right",
  marginLeft:"100px",
  marginRight:"43px",
  fontFamily:"Bogle",
  fontSize:"40px",
  cursor:"pointer"
},
controls:{
  float:"right",
  marginRight:"20px"
},
aemcontrols:{
  float:"right",
},
dateStyle:{
    color:"black",
  marginLeft:"18px",
  fontFamily:"Bogle",
  fontSize:"14px",
  textAlign:"start",
  width:"100px",
  display:"inline"
},
tabletdateStyle:{
  color:"black",
marginLeft:"18px",
fontFamily:"Bogle",
fontSize:"24px",
textAlign:"start",
width:"100px",
display:"inline"
},
mobiledateStyle:{
    color:"black",
  marginLeft:"58px",
  fontFamily:"Bogle",
  fontSize:"33px",
  textAlign:"start",
  width:"100px",
  display:"inline"
},
divider:{
  margin:"10px",
  border:"solid 0px #cbcbcb",
  cursor:"default"
},
mobiledivider:{
  margin:"40px",
  border: colorCodes.SOLID_BORDER_GRAY,
  cursor:"default"
},
postbutton:{
  width:"80px",
  height:"30px",
  color:"white",
  backgroundColor:"#041f41",
  fontFamily:"Bogle",
  fontSize:"14px",
  borderRadius:"16px",
  border:"none",
  float:"right",
  marginRight:"20px",
  marginBottom:"20px",
  cursor:"pointer"
},
aempostbutton:{
  width:"80px",
  height:"30px",
  color:"white",
  backgroundColor:"#041f41",
  fontFamily:"Bogle",
  fontSize:"14px",
  borderRadius:"16px",
  border:"none",
  float:"right",
  marginRight:"24px",
  marginBottom:"8px",
  cursor:"pointer"
},
tabletpostButton:{
  width:"100px",
  height:"50px",
  color:"white",
  backgroundColor:"#041f41",
  fontFamily:"Bogle",
  fontSize:"24px",
  borderRadius:"30px",
  border:"none",
  float:"right",
  marginRight:"50px",
  marginBottom:"20px",
  cursor:"pointer"
},
aemtabletpostButton:{
  width:"80px",
  height:"30px",
  color:"white",
  backgroundColor:"#041f41",
  fontFamily:"Bogle",
  fontSize:"14px",
  borderRadius:"16px",
  border:"none",
  float:"right",
  marginBottom:"20px",
  cursor:"pointer",
  marginRight:"10px"
},
mobilepostbutton:{
  width:"300px",
  height:"110px",
  color:"white",
  backgroundColor:"#041f41",
  fontFamily:"Bogle",
  fontSize:"45px",
  borderRadius:"100px",
  border:"none",
  float:"right",
  marginRight:"40px",
  marginBottom:"20px",
  cursor:"pointer"
},
disabledbutton:{
  width:"80px",
  marginBottom:"20px",
  height:"30px",
  color:"white",
  backgroundColor:"#bbc5d4",
  fontFamily:"Bogle",
  fontSize:"14px",
  borderRadius:"16px",
  border:"none",
  float:"right",
  marginRight:"20px",
  cursor:"default"
},
aemdisabledbutton:{
  width:"80px",
  marginBottom:"8px",
  height:"30px",
  color:"white",
  backgroundColor:"#bbc5d4",
  fontFamily:"Bogle",
  fontSize:"14px",
  borderRadius:"16px",
  border:"none",
  float:"right",
  marginRight:"24px",
  cursor:"default"
},
mobiledisabledbutton:{
  width:"300px",
  height:"110px",
  color:"white",
  backgroundColor:"#bbc5d4",
  fontFamily:"Bogle",
  fontSize:"45px",
  borderRadius:"100px",
  border:"none",
  float:"right",
  marginRight:"40px",
  cursor:"default",
  marginBottom:"20px",
},
tabletdisabledbutton:{
  width:"100px",
  height:"50px",
  color:"white",
  backgroundColor:"#bbc5d4",
  fontFamily:"Bogle",
  fontSize:"24px",
  borderRadius:"30px",
  border:"none",
  float:"right",
  marginRight:"50px",
  cursor:"default",
  marginBottom:"20px",
},
aemtabletdisabledbutton:{
  width:"80px",
  height:"30px",
  color:"white",
  backgroundColor:"#bbc5d4",
  fontFamily:"Bogle",
  fontSize:"14px",
  borderRadius:"16px",
  border:"none",
  float:"right",
  cursor:"default",
  marginBottom:"20px",
  marginRight:"10px"
},
drawerPaper: {
      width:"100%",
      height:"100%",
   '@media (min-width:0px) and (max-width:414px)': {
      width:"100%",
      height:"100%",
    }
  },
  drawerPosition:{
    height: '85.5vh',
    top:'6.58rem'
  },
  drawerPositionSam:{
    height: '77.5vh',
    top:'8.41rem',
  },
  viewNotesContainer:{
  // margin: '20px 30px 0 30px'
},
viewNotesIconContainer:{
  display:'flex',
  marginLeft: '24px'
},
viewNotesIcon:{
  height:'2em',
  width: '2em',
  color: '#ffff',
  marginLeft: '10%'
},
viewNotesTextMob:{
  fontSize: '2em',
  color: '#fff',
  fontFamily: 'Bogle'
},
viewAemNotesContainer:{
  marginTop:'31px',
  marginRight: '15px'
},
viewAemNotesIcon:{
  height:'17px',
  width: '17px',
  color: '#ffff',
  marginLeft: '10%'
},
viewAemNotesTextMob:{
  fontSize: '14px',
  color: '#fff',
  fontFamily: 'Bogle'
},
customSwapTextMobile: {
    fontFamily: 'Bogle',
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft:'24px',
    marginBottom:'25px',
    display: 'flex',
    alignItems: 'center'
  },
  customAemSwapTextMobile: {
    fontFamily: 'Bogle',
    fontSize: '8px',
    color: '#fff',
    marginBottom:'25px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginLeft: '25px'
  },
  viewNotesContainerDesktop: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px'
  },
  viewAemNotesContainerDesktop: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px'
  },
  viewNotesIconDesktop: {
    height: '24px',
    width: '24px',
    color: '#ffff',
  },
  viewNotesTextDesktop: {
    fontSize: '12px',
    color: '#fff',
    fontFamily: 'Bogle',
    alignSelf: 'start'
  }
};

export const affectedPage = {
  morePanel: {
    color: '#000',
    padding: '3px 2px',
    fontSize: 14,
    fontFamily: 'Bogle',
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: colorCodes.SPACE_BETWEEN
  },
  searchPanel: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'solid 1.5px #707070'
  },
  searchIcon: {
    position: 'relative',
    top: 4,
    width: '30px!important',
    paddingRight: '10px'
  },
  clearIcon: {
    position: 'relative',
    top: 4,
    width: '30px!important',
    paddingRight: '10px',
    color: '#c9c9c9'
  },
  moreLink: {
    color: '#0071ce'
  },
  tableHeader: {
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    fontSize: 18,
    margin: '0px 0 11px 0px',
    display: 'block'
  },
  search: {
    border: 'none',
    backgroundColor: '#f7f7f7',
    height: 30,
    width: '100%',
    '&:focus': {
      outline: 'none'
    },
    '&::-webkit-input-placeholder': {
      color: '#c9c9c9',
      fontFamily: 'bogle',
      fontSize: '16px',
    }
  },
  cardlbl: {
    whiteSpace: 'nowrap'
  },
  arrow: {
    color: '#c3c3c3',
    fontSize: 16
  },
  labelLink: {
    color: '#0457bc',
    fontFamily: 'Bogle',
    fontSize: 14,
    padding: '4px 0',
    textAlign: "end"
  },
  fullWidth: {
    width: '100%'
  },
  centerV: {
    alignSelf: 'center'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  datarow: {
    fontFamily: 'Bogle',
    fontSize: 16,
    color: '#000',
    paddingRight: 10,
    padding: '4px 0'
  },
  strikedContent: {
    fontFamily: 'Bogle',
    fontSize: 14,
    color: '#000',
    paddingRight: 10,
    padding: '4px 0',
    textDecoration: 'line-through'
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  halfRow: {
    width: '50%',
    marginRigth: '0px!important'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '11px 8px 6px 10px',
    backgroundColor: '#fff',
    marginTop: 17
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  labelWidth: {
    width: 100
  },
  labelSpan: {
    width: 110
  }
};

export const multicolorpanelstyles = {
  panelcontainer: {
    width: '100%',
    height: '33px',
    background: '#041e42',
    boxShadow:  colorCodes.BOX_SHADOW,
    zIndex: 99,
    position: 'fixed',
    top: '70px'
  },
  panelcontainerAem: {
    width: '100%',
    height: '33px',
    background: '#041e42',
    boxShadow:  colorCodes.BOX_SHADOW,
    zIndex: 99,
    position: 'fixed',
    top: '70px',
    left:0,
    '@media (min-width: 0px) and (max-width:567px)':{
      top: '54px',
    },
    '@media (min-width: 568px) and (max-width:1024px)':{
      top: '54px',
    }
  },
  panelcontainerMobile: {
    width: '100%',
    height: 80,
    background: '#041e42',
    boxShadow:  colorCodes.BOX_SHADOW,
    zIndex: 99,
    position: 'fixed',
    top: 111
  },
  highlightopenIcon: {
    color: '#eed26c',
    width: '13.9px',
    height: '18.2px',
    cursor: 'pointer',
  },
  highlightopenIconMob: {
    color: '#eed26c',
    height: '3em',
    width: '3em',
    cursor: 'pointer',
  },
  highlightCloseIconMob: {
    height: '3.7em',
    marginTop: '7em',
    cursor: 'pointer',
  },
  highlightCloseIcon: {
    cursor: 'pointer',
    height:'1rem',
    width:'1rem'
  },
  highlightCloseIconMobAem: {
    '@media (max-width: 567px)':{
      height: '13px',
      marginTop: '5px'
    }
  },
  highlightopencontainer: {
    width: '24px',
    height: '24px',
    border: 'solid 1px #eed26c',
    backgroundColor: '#ffffff'
  },
  panelbuttoncontiner: {
    justifyContent: 'flex-end',
    display: 'flex',
    height: 'inherit',
    marginRight: '50px',
    alignItems: 'center'
  },
  panelbuttoncontinerAem: {
    justifyContent: 'flex-end',
    display: 'flex',
    height: 'inherit',
    marginRight: '36px',
    alignItems: 'center',
    '@media(min-width: 0px) and (max-width:567px)':{
      alignItems:'end',
      marginRight:'20px',
      marginTop:'3px'
    }
  },
  radioAvatar: {
    cursor: 'pointer',
    border: colorCodes.SOLID_BORDER_CODE,
    background: 'none',
    height: '24px',
    width: '24px',
    marginRight: '15px'
  },
  radioAvatarMob: {
    cursor: 'pointer',
    border: colorCodes.SOLID_BORDER_CODE,
    background: 'none',
    height: '4em',
    width: '4em',
    marginTop: '6em',
    marginRight: '2em !important',
  },
  whiteBg: {
    cursor: 'pointer',
    border: colorCodes.SOLID_BORDER_CODE,
    background: '#ffff',
    height: '4em',
    width: '4em',
    marginTop: '6em'
  },
  radio: {
    cursor: 'pointer',
  },
  radioMob: {
    cursor: 'pointer',
    height: '3em',
    width: '3em'
  },
  secondRadio: {
    color: '#33ff91 !important',
    cursor: 'pointer',
  },
  secondRadioMob: {
    color: '#33ff91 !important',
    cursor: 'pointer',
    height: '3em',
    width: '3em'
  },
  thirdRadio: {
    color: '#f0b9a5 !important',
    cursor: 'pointer',
  },
  thirdRadioMob: {
    color: '#f0b9a5 !important',
    cursor: 'pointer',
    height: '3em',
    width: '3em'
  },
  radioWidthMob: {
    marginRight: '2em',
    marginTop: '5em'
  },
  radioAvatarHide: {
    cursor: 'pointer',
    background: 'none',
    height: '24px',
    width: '24px',
    marginRight: '15px'
  },
  radioAvatarHideMob: {
    cursor: 'pointer',
    background: 'none',
    height: '1.5em',
    width: '1.5em',
    marginRight: '1.2em',
  },
  radioAvatarAemHideMob:{
    cursor: 'pointer',
    background: 'none',
    bottom: '0.15rem'
  },
  deleteAemIcon: {
    backgroundColor: colorCodes.BG_WHITE_IMP,
    color: "black",
    borderRadius: '50%',
    cursor: 'pointer',
    position:'relative',
    top:'0.45rem',
    left:'0.35rem'
  },
  radioAemMob:{
    cursor: 'pointer',
    height: '3em',
    width: '1em'
  },
  deleteAemIconHide:{
    background: "none",
    color: "#fff",
    borderRadius: '50%',
    cursor: 'pointer',
    position:'relative',
    top:'0.25rem',
    left:'0.35rem',
    '@media(min-width:640px)':{
      top:0
    }
  },
  radioAvatarAemMob: {
    cursor: 'pointer',
    border: colorCodes.SOLID_BORDER_CODE,
    background: 'none',
    height: '1.8em',
    width: '1.8em',
  },
  deleteIcon: {
    backgroundColor: colorCodes.BG_WHITE_IMP,
    color: "black",
    marginLeft: '15px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  deleteIconHide: {
    background: "none",
    color: "#fff",
    borderRadius: '50%',
    cursor: 'pointer',
  },
  deleteIconMob: {
    backgroundColor: colorCodes.BG_WHITE_IMP,
    color: "black",
    borderRadius: '50%',
    cursor: 'pointer',
    marginTop: '4em',
    height: '3em',
    width: '3em'
  },
  deleteIconHideMob: {
    background: "none",
    color: "#fff",
    borderRadius: '50%',
    cursor: 'pointer',
    height: '2em',
    width: '2em'
  },
  deleteIconMargin:{
    '@media(max-width:567px)':{
      marginTop:'auto'
    }
  },
  containerHeight:{
    height:'4rem !important'
  },
  hightlightDimention:{
    height:'3rem !important',
    width : '3rem !important',
    marginRight:'1.5rem !important'
  },
  hightlightIcon:{
    fontSize:'3.5rem !important'
  }
};

export const assessmentbuttoncontainerstyles = {
  assessmentbtnContainer: {
    background: '#ffffff',
    justifyContent: 'center',
    padding: colorCodes.PADDING_10_10_10_10,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: 1,
    x: 101,
    zIndex: 99,
    boxShadow: colorCodes.BOX_SHADOW_NEG
  },
  assessmentbtnContainerMobile: {
    background: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '20px',
    opacity: 1,
    zIndex: 100,
    boxShadow: colorCodes.BOX_SHADOW_NEG
  },
  assessmentbtnContainerAem: {
    '@media (min-width: 0px) and (max-width: 567px)': {
      background: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: 1,
    zIndex: 100,
    boxShadow: colorCodes.BOX_SHADOW_NEG,
    padding: colorCodes.PADDING_10_10_10_10,
    },
    '@media (min-width: 568px)':{
      background: '#ffffff',
      justifyContent: 'center',
      padding: colorCodes.PADDING_10_10_10_10,
      position: 'fixed',
      display: 'flex',
      flexDirection: 'row',
      bottom: 0,
      left: 0,
      width: '100%',
      opacity: 1,
      x: 101,
      zIndex: 99,
      boxShadow: colorCodes.BOX_SHADOW_NEG
    }
  },
  takeasmntBtn: {
    fontSize: '18px!important',
    '@media (min-width: 0px) and (max-width: 640px)': {
      fontSize: colorCodes.FONT_14_IMP,
    },
    '@media (min-width: 641px) and (max-width: 1024px)': {
      fontSize: colorCodes.FONT_16_IMP,
    }
  },
  buttonMob: {
    height: '66.7% !important', width: '36%', borderRadius: '4em !important', fontSize: '2em !important'
  },
  buttonMobAem: {
    '@media (min-width: 0px) and (max-width:567px)' :{
      fontSize: '11px !important'
    }
  }
}

export const userNotFound = {
  container: {
    display: 'table',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  },
  middleRow: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  textStyle: {
    fontFamily: "Bogle",
    textAlign: 'center'
  }
};

export const noMatch = {
  container: {
    display: 'table',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  },
  middleRow: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  textStyle: {
    fontFamily: "Bogle",
    textAlign: 'center'
  }
};

export const healthAndWellnessLoginStyles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  container: {
    minHeight:'100vh',
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent: 'center'
  },
  logotext: {
    fontSize:'33px',
    fontFamily:'Bogle',
    color: '#000000',
    '@media (min-width: 0px) and (max-width: 640px)': {
      fontSize:'25px'
    },
  },
  logo: {
    width: '65px',
    height:'72px',
    marginRight: '22px' ,
    '@media (min-width: 0px) and (max-width: 640px)': {
      width: '50px',
      height:'56px',
      marginRight: '16px'
    },
  },
  loginText: {
    fontFamily: 'Bogle',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '81px',
    color: '#000000',
    '@media (min-width: 0px) and (max-width: 640px)': {
      marginTop: '27px',
      textAlign: 'center'
    },
  },
  createAccountText: {
    fontFamily: 'Bogle',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '27px',
    color: '#000000'
  },
  TextInput: {
    width: '343px',
    height: '56px',
    justifySelf: 'center',
    display: 'flex',
    '@media (min-width: 0px) and (max-width: 640px)': {
      width: '254px'
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#909196",
      }
    },
    "& .MuiFormLabel-root": {
      fontFamily: 'Bogle',
      "&.Mui-focused": {
        color: "#909196"
      }
    }
  },
  TextInputSignup: {
    width: '343px',
    height:'56px',
    justifySelf:'center',
    display:'flex',
    marginBottom: '20px',
    '@media (min-width: 0px) and (max-width: 640px)': {
      width: '269px'
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#909196",
      }
    },
    "& .MuiFormLabel-root": {
      fontFamily: 'Bogle',
      "&.Mui-focused": {
        color: "#909196"
      }
    }
  },
  name: {
    width: '165px',
    height: '56px',
    '@media (min-width: 0px) and (max-width: 640px)': {
      width: '127px !important'
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#909196",
      }
    },
    "& .MuiFormLabel-root": {
      fontFamily: 'Bogle',
      "&.Mui-focused": {
        color: "#909196"
      }
    }
  },
  zipCode: {
    width: '165px',
    height: '56px',
    marginLeft: '13px',
    '@media (min-width: 0px) and (max-width: 640px)': {
      width: '127px !important'
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#909196",
      }
    },
    "& .MuiFormLabel-root": {
      fontFamily: 'Bogle',
      "&.Mui-focused": {
        color: "#909196"
      }
    }
  },
  forgotPasswordlogin: {
    display:'flex',
    justifyContent:colorCodes.SPACE_BETWEEN,
    alignItems:'center'
  },
  rememberMe: {
    color:'#636363',
    fontSize :'14px !important',
    fontFamily: 'Bogle',
    paddingTop:'5px'
  },
  linkStyle: {
    fontFamily: 'Bogle',
    color:'#0071ce',
    fontSize :'14px',
    fontWeight: 'bold'},
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Bogle'
  },
  passwordSpacing: {
    letterSpacing: '5px'
  },
  submitButton: {
    marginTop: '31px'
  },
  SSO: {
    marginTop: '26px',
    cursor:'pointer'
  },
  question: {
    marginTop: '10px',
    fontFamily: 'Bogle'
  },
  signupLink: {
    marginLeft: '8px'
  },
  errorText: {
    color: 'red',
    fontFamily: 'Bogle',
    width: '343px',
    height:'56px',
    justifySelf:'center',
    display:'flex',
    '@media (min-width: 0px) and (max-width: 640px)': {
      width: '254px'
    },
    padding: colorCodes.PADDING_10_10_10_10
  },
  account: {
    color: '#000000',
    fontFamily: 'Bogle'
  },
  errMessage :{
    background:'red',
    color:'white',
    textAlign: colorCodes.TEXT_ALIGN_CENTER_IMP,
    fontFamily: 'Bogle',
    fontSize: '18px',
    justifyContent: colorCodes.SPACE_BETWEEN,
    marginTop: '1rem',
    padding:colorCodes.PADDING_20_10,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: 1,
    zIndex: 2,
  },
  pushMessage :{
    background:'green',
    color:'white',
    textAlign: colorCodes.TEXT_ALIGN_CENTER_IMP,
    fontFamily: 'Bogle',
    fontSize: '18px',
    justifyContent: colorCodes.SPACE_BETWEEN,
    marginTop: '1rem',
    padding:colorCodes.PADDING_20_10,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    opacity: 1,
    zIndex: 2,
},
hide :{
  display: 'none'
},
closeIcon :{
  '@media (min-width: 0) and (max-width: 360px)' :{
      display: 'none'
  },
  '@media (min-width: 360px)':{
      cursor:'pointer',
      position: 'fixed',
      right: 0,
      marginRight: '20px'
  }
},
apiErrorPadding:{
  paddingTop: '25px',
  textAlign: 'center'
},
closeIconMobile :{
  display: 'none',
  '@media (min-width: 0) and (max-width: 360px)' :{
      cursor:'pointer',
      position: 'fixed',
      alignItems: 'center',
      display: 'block',
      left:'50%',
  }
}
  }
