import React from 'react';
import { makeStyles } from '../../../../lib/materialui';

const AssessmentHeader = (props) => {
    const { assessmentSubmitted, courseName, handleClose, handleSubmission } = props;

    const useStyles = makeStyles(theme => (styles));
    const classes = useStyles();
    const { header, logoText, logo, pauseText } = classes;

    return <>
        <div id="assessment-header-container" className={header}>
            <div id="assessment-header-label" className={logoText}>
                <div id="assessment-header-label-img"><img className={logo} src={'/images/general/spark.png'} /></div>
                <div id="assessment-header-label-text" className={logoText}>
                    Assessment {courseName[0] && (<>- {courseName[0].parent_name}</>)}
                </div>
            </div>
            <div id="assessment-header-controls" className={pauseText}>
                {
                    assessmentSubmitted ? (
                        <span id="assessment-header-done-control" onClick={handleSubmission}>Done</span>
                    ) : (
                            <span id="assessment-header-pause-control" onClick={handleClose}>Pause</span>
                        )
                }
            </div>
        </div>
    </>
}

const styles = {
    pauseText: {
        fontFamily: 'Bogle',
        fontSize: 24,
        margin: '0 47px 0 0',
        cursor: 'pointer',
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 16,
        },
    },
    logo: {
        height: 40,
        margin: '0 30px 0 51px',
        '@media (min-width:0px) and (max-width:640px)': {
            margin: '11px 15px 0 10px',
        }
    },
    logoText: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Bogle',
        fontSize: 32,
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 18,
            width: '63%',
            alignItems: 'center'
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        height: 81,
        backgroundColor: '#337cc0',
        color: '#fff',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '2px 7px 14px -9px navy',
        marginBottom: 40,
    }
}

export default AssessmentHeader;
