import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Messages } from '../../../../constants/ErrorConstants';
import { localStorageConstants } from '../../../../constants/LocalStorageConstants';
import { updateCourseProgress, getAssessmentAnswersWithTimeout, getAssessmentAnswers,
    submitAssessment, submitAssessmentAnswer, getAssessmentDataByCourseIdType } from '../../../../dao/assessment';
import { postAssessmentDataToLRS } from "../../../../dao/lrsdata";
import { Checkbox, LinearProgress, makeStyles } from '../../../../lib/materialui';
import { getAssmtSessionToken } from '../../../../utils/common';
import { getItem } from '../../../../utils/storage';
import {
    getAssessmentInProgress, getCounterInProgress, getDivisionId,
    getRole, getTypeInProgress, setAssessmentInProgress, setCounterInProgress,
    setTypeInProgress
} from '../../../../utils/userprofile';
import Button from '../../../common/button';
import Loading from '../../../loading';
import SnackbarComponent from '../snackbar';
import {
    trackBeforeSubmitAssessmentButtonClickEvent,
    trackAfterSubmitAssessmentButtonClickEvent,
    trackAssessmentAnswerClick
  } from '../../../../utils/userTelemetry/index';

const AssessmentBody = (props) => {
    const {
        assessmentId, id, courseId, type, questions, isResumeAssmt, handleAssessmentSubmitted,
        handleSubmittetData, courseName, wmWeek, scoreArr, handleSessionErr, code, start, end } = props;

    const useStyles = makeStyles(theme => (styles));
    const classes = useStyles();
    const { content, leftSpace, mainArea, rightSpace, question, asmntTitle, answers, answerBlock, progressBar,
        pageNo, nextBackBtn, nextBackBtnSm, submit, btnsRow, btnDime } = classes;

    useEffect(() => {
        setLoading(true);
        splCharValidation();
        getAssessmentAnswersAPI(true);
        if(getAssessmentInProgress() !== courseId){
            setCounterInProgress(0);
            setAssessmentInProgress(courseId);
        }
    }, []);

    const splCharValidation = async () => {
        let ansarray = [];
        questions.forEach(qusn => {
            if (/[^\u0000-\u00ff\u0178]/.test(qusn.question)) {
                qusn.question = qusn.question.replace(/[^\u0000-\u00ff\u0178]/g, '');
            }
            ansarray = [];
            qusn.answers.forEach(ans => {
                if (/[^\u0000-\u00ff\u0178]/.test(ans)) {
                    ans = ans.replace(/[^\u0000-\u00ff\u0178]/g, '');
                }
                ansarray.push(ans);
            })
            qusn.answers = ansarray;
        });
    }

    const getAssessmentAnswersAPI = async (isInitialLoad) => {
        let assessmentAnswersData = await getAssessmentAnswers(assessmentId);
        if (!assessmentAnswersData) {
            assessmentAnswersData = []
            assessmentAnswersData = await handleGetAnswerFailureRequest(assessmentAnswersData)
        } else {
            assessmentAnswersData = await handleFailureRequest(assessmentAnswersData)
        }
        let validAnswers = []
        let assessmentAnswerWithoutVerbiage
        if (assessmentAnswersData && Array.isArray(assessmentAnswersData) && assessmentAnswersData.length > 0) {
            
            assessmentAnswerWithoutVerbiage = assessmentAnswersData.map(answer => {
                if (answer.question.startsWith('question-')) {
                    answer.question = answer.question.replace('question-', '')
                }
                return answer
            })
            setData(assessmentAnswersData);
            validAnswers = assessmentAnswerWithoutVerbiage.filter(function (id) {
                let result = false
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].question_id === id.question) {
                        result = true
                    }
                }
                return result
            })
        }
        else {
            console.log('Empty assessment answers data');
        }
        setAnswer(validAnswers);
        if (isInitialLoad) {
            const counter = getAssessmentInProgress() === courseId && getTypeInProgress() === type ? Number(getCounterInProgress()) : 0
            counter < questions.length ?
                updateCounter(counter) :
                validAnswers.length >= questions.length ? updateCounter(validAnswers.length - 1) : updateCounter(questions.length - 1)
        }
        setLoading(false);
    };
    const handleGetAnswerFailureRequest = async (assessmentAnswersData) => {
        if (data && Array.isArray(data) && data.length > 0) {
            data
            .forEach((failureReqObj) => {
                const questionAlreadyAnswerIndex = assessmentAnswersData.findIndex(o => o.question === `question-${failureReqObj.question}`)
                if (questionAlreadyAnswerIndex >= 0) {
                    assessmentAnswersData[questionAlreadyAnswerIndex].answer = failureReqObj.answer
                    assessmentAnswersData[questionAlreadyAnswerIndex].networkCallStatus = failureReqObj.networkCallStatus
                } else {
                    assessmentAnswersData.push({
                        question: `question-${failureReqObj.question}`,
                        answer: failureReqObj.answer,
                        networkCallStatus: failureReqObj.networkCallStatus
                    }) 
                }
            })
        }
        return assessmentAnswersData
    }
    const handleFailureRequest = async (assessmentAnswersData) => {
        if (data && Array.isArray(data) && data.length > 0) {
            data.filter((questionObj)=>{
                return questionObj.networkCallStatus === 'Failed'
            }).forEach((failureReqObj) => {
                const questionAlreadyAnswerIndex = assessmentAnswersData.findIndex(o => o.question === `question-${failureReqObj.question}`)
                if (questionAlreadyAnswerIndex >= 0) {
                    assessmentAnswersData[questionAlreadyAnswerIndex].answer = ''
                    assessmentAnswersData[questionAlreadyAnswerIndex].networkCallStatus = 'Failed'
                } else {
                    assessmentAnswersData.push({
                        question: `question-${failureReqObj.question}`,
                        answer: '',
                        networkCallStatus: 'Failed'
                    }) 
                }
            })
        }
        return assessmentAnswersData
    }

    const updateAnswerAPIStatus = async (params, status) => {

        const questionAlreadyAnswerIndex = data.findIndex(o => o.question === params.questionId)
        if (questionAlreadyAnswerIndex >= 0) {
            data[questionAlreadyAnswerIndex].answer = params.answer
            data[questionAlreadyAnswerIndex].networkCallStatus = status
        } else {
            data.push({ 
                    question: params.questionId,
                    answer: params.answer,
                    networkCallStatus: status
                })
        }
        setData(data)
        setAnswer(data)
    }
    const submitAssessmentAnswerAPI = async (params) => {
        const assmtToken = await getAssmtSessionToken();
        params = { ...params, assmtToken }
        const assessmentAnswersData = await submitAssessmentAnswer(params);
        if (assessmentAnswersData) {
            if (assessmentAnswersData.isTokenValid) {
                updateAnswerAPIStatus(params, 'Success')
                getAssessmentAnswersAPI(false);
            } else {
                handleSessionErr(Messages.assessment_session_error);
            }
        } else {
            // handling NUll case specially when network call fails/server busy/time-out.
            updateAnswerAPIStatus({...params, answer: ''}, 'Failed')
            getAssessmentAnswersAPI(false);
        }
    };

    const submitAssessmentAPI = async (params) => {
        const assmtToken = await getAssmtSessionToken();
        params = { ...params, assmtToken }
        trackBeforeSubmitAssessmentButtonClickEvent(params.academyId, params.courseRoster, params.assessmentId, params.courseId,params.questions,code);
        const submitAssessmentData = await submitAssessment(params, false);
        trackAfterSubmitAssessmentButtonClickEvent(
            params.academyId,
            params.courseRoster,
            params.assessmentId,
            params.courseId,
            code,
            submitAssessmentData.ParticipantPerformanceResult.attemptNum);
        setReqFailureError('')
        if (submitAssessmentData) {
            if (submitAssessmentData.isTokenValid) {
                handleAssessmentSubmitted();
                handleSubmittetData(submitAssessmentData.ParticipantPerformanceResult);
                setSubmittedData(submitAssessmentData.ParticipantPerformanceResult);
                const lrsParams = {
                    ...params,
                    ...submitAssessmentData.ParticipantPerformanceResult,
                    role: getRole() || '',
                    divisionId: getDivisionId(),
                    wmWeek,
                    scoreArr,
                    start,
                    end,
                    courseStatus: 'completed'
                }
                await postAssessmentDataToLRS(lrsParams)
                try {
                    if (submitAssessmentData.ParticipantPerformanceResult &&
                        submitAssessmentData.ParticipantPerformanceResult.pass &&
                        submitAssessmentData.ParticipantPerformanceResult.pass === 'pass') {
                        await updateCourseProgress(lrsParams)
                    }
                } catch (error) {
                    console.error('Error while making call to LIS', error)
                }
            }
            else {
                setAssessmentSubmitError(true);
                console.log('Error while submitting assessment');
                handleSessionErr(Messages.assessment_session_error);
            }
        } else {
            setAssessmentSubmitError(true);
            console.log('Error while submitting assessment');
            handleSessionErr("Assessment submission not received. Please click submit to try again.");
        }
        setLoading(false);
    };
    const [submitBtnType, setSubmitBtnType] = useState('primary')
    const [reqFailureError, setReqFailureError] = useState('');
    const [loading, setLoading] = useState(true);
    const [counter, setCounter] = useState(0);
    const [checked, setChecked] = useState('');
    const [data, setData] = useState([]);
    const [answer, setAnswer] = useState();
    const [submittedData, setSubmittedData] = useState();
    const [assessmentSubmitError, setAssessmentSubmitError] = useState(false);
    setTypeInProgress(type);

    const updateCounter = (value) => {
        setCounter(value)
        setCounterInProgress(value);
    }

    const handleCounter = toggle => {
        setLoading(true);
        setTimeout(function() {
            setLoading(false);
            toggle && counter < questions.length && updateCounter(counter + 1);
            !toggle && counter > 0 && updateCounter(counter - 1);
            setChecked('');
          }, window.ENV.ARTIFICIAL_UI_DELAY);
    };

    const handleSubmit = async (answers) => {
        setSubmitBtnType('disabled')
        setReqFailureError(`Please wait. Do not close/refresh the page.`)
        // let assessmentAnswersData = await getAssessmentAnswers(assessmentId);
        const questionIds = answers.map(val => val.question);
        const assmtToken = await getAssmtSessionToken();
        let assessmentAnswersData = await getAssessmentAnswersWithTimeout(assessmentId, questionIds, assmtToken, false);
        if(assessmentAnswersData && assessmentAnswersData.assessmentAndQuestionValidation){
            const assmtstatus = assessmentAnswersData.assessmentAndQuestionValidation
            if(assmtstatus.isAssmtAltered){
                handleSessionErr("An error has occurred, this assessment has not been submitted. Please restart the assessment.");
            }else if (assmtstatus.isQuestionsAltered){
                handleSessionErr("An error has occurred, this assessment has not been submitted. Please click “Resume Assessment” to complete.");
            }else{
                if (assessmentAnswersData && assessmentAnswersData.assessmentAnswers) {
                    assessmentAnswersData = assessmentAnswersData.assessmentAnswers
                }
                setSubmitBtnType('primary')
                let isAssessmentReadyToSubmit = false
                if (assessmentAnswersData) {
                    if (Array.isArray(assessmentAnswersData)) {
                        if (questions.length === assessmentAnswersData.length) {
                            isAssessmentReadyToSubmit = true
                        } else {
                            if(assessmentAnswersData.length===0 && !assmtstatus.isTokenValid){
                                handleSessionErr(`The assessment has been started/submitted on a different device or window. If you believe this is an error,
                                    please reach out to your Academy Coordinator or Program Manager.`);
                                return
                                }
                            const unattemptedQuestions = []
                            const unattemptedQuestionsIndexs = assessmentAnswersData.length === 0 ? Array.from({ length: questions.length }, (_, i) => i + 1) : []
                            assessmentAnswersData.length === 0 ?
                                Array.prototype.push.apply(unattemptedQuestions, questions) : questions.map((question, index) => {
                                    const questionFound = assessmentAnswersData.find((obj) => {
                                        return obj.question === `question-${question.question_id}`
                                    })
                                    if (!questionFound) {
                                        unattemptedQuestions.push(question)
                                        unattemptedQuestionsIndexs.push(index + 1)
                                    }
                                })
                            // Updating local copy in case there are questions unattempted.
                            if (data && Array.isArray(data) && data.length > 0) {
                                const udatedAnswers = data.map((obj) => {
                                    const questionFound = unattemptedQuestions.find((unattemptedQuestion) => {
                                        return unattemptedQuestion.question_id === obj.question
                                    })
                                    if (questionFound) {
                                        obj.answer = ''
                                    }
                                    return obj
                                })
                                setData(udatedAnswers)
                            }
                            setReqFailureError(`Answer not selected for question(s) ${unattemptedQuestionsIndexs.join(',')}. Please submit after all questions have been answered.`)
                            return
                        }
                    }
                } else {
                    setReqFailureError(`Something went wrong. Please try again.`)
                }
                if (!isAssessmentReadyToSubmit) { // ignoring the failure requests if there is already an answer available for each question.
                    let failureReqs = []
                    if (data && Array.isArray(data) && data.length > 0) {
                        failureReqs = data.filter((reqObj) => {
                            return (reqObj.networkCallStatus === 'Failed' || reqObj.networkCallStatus === 'Not Started')
                        }).map((failureReqObj) => { return failureReqObj.question })
                    }
                    if (failureReqs && Array.isArray(failureReqs) && failureReqs.length > 0) {
                        const failureQuestionIndexs = questions.reduce((arr, e, i) => ((failureReqs.includes(e.question_id)) && arr.push(i + 1), arr), [])
                        setReqFailureError(`Answer not selected for question(s) ${failureQuestionIndexs.join(',')}. Please submit after all questions have been answered.`)
                        return
                    }
                    return
                }
                setLoading(true);
                const questionArray = _.map(answers, answer => {
                    return { "<<answer>>": answer.answer, "<<id>>": answer.question };
                });
                const courseRoster = getItem(localStorageConstants.ROSTER_ID) ? getItem(localStorageConstants.ROSTER_ID) : 'NA';
    
                const params = {
                    questions: questionArray,
                    courseId,
                    courseName,
                    assessmentId: assessmentId,
                    academyId: id.academy_id,
                    type,
                    courseRoster
                };
                submitAssessmentAPI(params);
            }
        }else{
            setReqFailureError(`Something went wrong. Please try again.`);
            setSubmitBtnType('primary')
        }
    };

    const handleChecked = (index) => {
        const answer = questions[counter].answers[index]
        const roster = getItem(localStorageConstants.ROSTER_ID) ? getItem(localStorageConstants.ROSTER_ID) : 'NA';
        const question = questions[counter].question_id;
        trackAssessmentAnswerClick(assessmentId,roster,courseId,question,answer,code)
        setChecked(answer);
        const params = {
            assessmentId,
            question: `question-${questions[counter].question_id}`,
            answer,
            updateReason: 'taking test',
            questionId: questions[counter].question_id,
            answerIndex: index
        };
        
        updateAnswerAPIStatus(params, 'Not Started') // Update UI instantly without waiting for network call success.
        submitAssessmentAnswerAPI(params);
    };

    const getPrechecked = (answer, index) => {
        const answerAvailable = _.find(
            data,
            o => o.question === questions[counter].question_id
        );
        if (answerAvailable && answerAvailable.answer && answerAvailable.answer.startsWith('ans-index-')) {
            const answerIndex = answerAvailable.answer.split('ans-index-')[1]
            return answerAvailable && answerIndex == index;
        } else {
            return answerAvailable && answerAvailable.answer == answer;
        }
        
    };

    return <>
        {loading && <Loading />}
        {
            !loading &&
            <div id="assessment-body-container" className={content}>
                <div id="assessment-body-move-left" className={leftSpace}>
                    {
                        counter > 0 && 
                        <img id="assessment-body-move-left-click" onClick={() => handleCounter(false)} className={nextBackBtn} src={'/images/general/leftButton.png'} />
                    }
                </div>

                <div id="assessment-body-display-area" className={mainArea}>
                    <div id="assessment-body-question" className={question}>
                        <span>
                            {questions[counter].question}
                        </span>
                    </div>
                    <div id="assessment-body-answers" className={asmntTitle}>{questions[counter].topic}</div>

                    {
                        questions[counter].answers.map((answer, index) => (
                            <div key={index} className={answers} >
                                <div id={"assessment-body-answer-" + index} className={answerBlock} onClick={() => handleChecked(index)}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={getPrechecked(answer, index) || false}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            label={'Answer Here'}
                                        />}
                                        label={answer}
                                    />
                                </div>
                            </div>
                        ))
                    }

                    <div id="assessment-body-progessbar-buttons" className={submit}>
                        {answer && answer.length === questions.length ? (
                            <Button 
                                id="assessment-body-progessbar-button-submit"
                            openEvent={() => {
                                setTimeout(function() {
                                    handleSubmit(answer)
                                  }, window.ENV.ARTIFICIAL_UI_DELAY);
                            }} className={btnDime} buttonText={'Submit'} 
                             buttonType={`${submitBtnType}`}
                             buttonStyle={'pill'} buttonSize={'large'} />
                        ) : ('')}
                    </div>

                    <div id="assessment-body-progessbar-container" className={progressBar}>
                        <LinearProgress variant="determinate" value={(counter + 1) * 100 / questions.length} />
                    </div>

                    <div id="assessment-body-progessbar-buttons-mobile" className={btnsRow}>
                        <div>
                            {
                                counter > 0 &&
                                    <img
                                        id="assessment-body-progessbar-button-left-mobile"
                                        onClick={() => handleCounter(false)}
                                        className={nextBackBtnSm}
                                        src={'/images/general/leftButtonSm.png'}
                                    />
                            }
                        </div>

                        <div>
                            {
                                counter < questions.length - 1 &&
                                    <img
                                        id="assessment-body-progessbar-button-right-mobile"
                                        onClick={() => handleCounter(true)}
                                        className={nextBackBtnSm}
                                        src={'/images/general/rightButtonSm.png'}
                                    />
                            }
                        </div>

                    </div>
                    <div id="assessment-body-current-question-indicator" className={pageNo}>{counter + 1} of {questions.length}</div>
                </div>
                <div id="assessment-body-move-right" className={rightSpace}>
                    {
                        counter < questions.length - 1 && 
                        <img id="assessment-body-move-right-click" onClick={() => handleCounter(true)} className={nextBackBtn} src={'/images/general/rightButton.png'} />
                    }
                </div>
            </div>
        }
        {
            reqFailureError && <SnackbarComponent
                open={reqFailureError}
                label={reqFailureError}
                okBtn={setReqFailureError}
                 />
        }
        {
            assessmentSubmitError && <SnackbarComponent
                open={assessmentSubmitError}
                label={submittedData && submittedData.error ? Messages.test_submit_error : Messages.get_assessment_failed} />
        }
    </>

}

const styles = {
    btnDime: {
        height: '50px !important',
        width: '142px !important',
        '@media (min-width: 0) and (max-width: 640px)': {
            height: '42px !important',
            width: '120px !important',
        },
    },
    btnsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20
    },
    mainArea: {
        width: '100%'
    },
    submit: {
        fontFamily: 'Bogle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        height: 44,
        marginBottom: 19
    },
    nextBackBtn: {
        width: 118,
        cursor: 'pointer',
        '@media (min-width: 0) and (max-width: 768px)': {
            display: 'none'
        },
    },
    nextBackBtnSm: {
        width: 69,
        cursor: 'pointer',
        display: 'none',
        '@media (min-width: 0) and (max-width: 768px)': {
            display: 'block'
        },
    },
    pageNo: {
        fontFamily: 'Bogle',
        fontSize: 24,
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 7,
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 18,
        },
    },
    progressBar: {
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: '#4E8EC8'
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#ffcc00'
        }
    },
    answerBlock: {
        fontFamily: 'Bogle',
        height: 'auto',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: 40
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#c6157d'
        },
        '@media (min-width: 0) and (max-width: 640px)': {
            height: 'auto',
            border: 'solid 1px blue!important',
            paddingLeft: 22
        },
        '@media (min-width: 641px) and (max-width: 1024px)': {
            height: 'auto',
        },
        '@media (orientation:landscape) and (min-width: 641px) and (max-width: 1024px)': {
            height: 'auto'
        },
    },
    answers: {
        fontFamily: 'Bogle',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 20,
        '& .MuiFormControlLabel-label': {
            fontFamily: 'Bogle',
            fontSize: 22,
            '@media (min-width: 0) and (max-width: 640px)': {
                fontSize: 16,
            }
        }
    },
    asmntTitle: {
        fontFamily: 'Bogle',
        fontSize: 24,
        color: '#fff',
        margin: '42px 0 69px 0',
        textAlign: 'center',
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 16,
            margin: '20px 0 20px 0',
        },
    },
    question: {
        fontFamily: 'Bogle',
        fontSize: 30,
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '33px',
        textAlign: 'center',
        justifyContent: 'center',
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 18,
            lineHeight: '24px',
        },
    },
    page: {
        backgroundColor: '#337cc0',
    },
    content: {
        fontFamily: 'Bogle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#337cc0',
    },
    pauseText: {
        fontFamily: 'Bogle',
        fontSize: 24,
        margin: '0 47px 0 0'
    },
    logo: {
        height: 40,
        margin: '0 30px 0 51px'
    },
    logoText: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Bogle',
        fontSize: 32,
    },
    rightSpace: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 0) and (max-width: 640px)': {
            width: '5%',
        },
        '@media (min-width: 641px) and (max-width: 1024px)': {

        },
    },
    leftSpace: {
        width: '22%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 0) and (max-width: 640px)': {
            width: '5%',
        },
        '@media (min-width: 641px) and (max-width: 1024px)': {

        },
    }
}

export default AssessmentBody;