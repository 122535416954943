
import { fetchUserProfile, fetchUserProfileWithUserid, USER_QUERY } from "../../../constants/QueryConstants";
import { localStorageConstants } from '../../../constants/LocalStorageConstants';
import { setItem } from '../../../utils/storage';
import { callService as callRestService, callExternalServicePost } from "../../../utils/services/rest";
import { callService as callEduService } from "../../../utils/services/graphql";


export async function fetchUserProfileData(type, params) {

    if (type === "code") {

        if (window.ENV.REACT_APP_AUTH_TYPE === "pingfed") {
            const envConst = window.ENV.REACT_APP_IAM_AUTHENTICATION_URL.includes("pfedprod") ? "prod" : "stage";
            const jsonBody = {
                clientid: 'eduacademy',
                env: envConst,
                authtype: 'cert',
                code: params.code,
                redirecturi: params.redirecturi
            };

            return await callExternalServicePost('/api/fetchUserProfileWithPingfed', jsonBody);

        } else {

            const initialQuery = fetchUserProfile;
            const finalQuery = initialQuery.replace("<<code>>", params.code).replace("<<redirectURI>>", params.redirecturi).replace("<<authorization>>", params.authorization);
            return await callExternalServicePost('/external-service-proxy/user-services', { query: finalQuery, queryName: "fetchUserProfile" });

        }

    } else if (type === "userid") {
        const initialQuery = fetchUserProfileWithUserid;
        setItem(localStorageConstants.AUTH_CODE, Math.random() * 20);
        const finalQuery = initialQuery.replace("<<userid>>", params.userid).replace("<<storenum>>", params.storenum);
        return await callExternalServicePost('/external-service-proxy/user-services', { query: finalQuery, queryName: "fetchUserProfileWithUserId" });

    }
    else if (type === "UIN") {
        const initialQuery = USER_QUERY;
        const UIN = parseInt(params.replace(/^0+/, ''), 10)
        setItem(localStorageConstants.AUTH_CODE, Math.random() * 20);
        let finalQuery = initialQuery.replace(new RegExp("<<win>>"), UIN);
        finalQuery = finalQuery.replace(new RegExp("<<includeDeleted>>"), false);
        return await callEduService(finalQuery);

    }
}

export async function refreshToken() {
    return await callRestService('/api/refreshToken', '' , '', 'axiosGet');
}