import React from 'react'
import Proptypes from 'prop-types'
import { DataLoaderStyles as useStyles } from '../../assets/styles'
import CircularProgress from '@material-ui/core/CircularProgress';
const Loading = ({title}) => {
    const classes = useStyles();
    return (
        <div id="loading-container" className={classes.ParentContainer}>
            <section id="loading-section" className={classes.Container}>
                <CircularProgress id="loading-progress-bar" size={60} thickness={2} className={classes.logo} />
                <div id="loading-text-container" className={classes.LoadText}>
                    <span id="loading-text">
                        {title}
                    </span>
                </div>
            </section>
        </div>
    );
}


Loading.propTypes ={
    title:Proptypes.string
}

export default Loading

