import React from 'react';
import _ from 'lodash';
import { makeStyles, FiberManualRecordIcon } from '../../../../lib/materialui';
import { removeAssessmentInProgress, removeCounterInProgress, removeTypeInProgress } from '../../../../utils/userprofile';

const AssessmentResult = (props) => {
    const { submittedData } = props;

    const useStyles = makeStyles(theme => (styles));
    const classes = useStyles();
    const { content, resultBox, resultDetails, resultHdr, resultSummary, bulletFail, bulletPass } = classes;

    removeAssessmentInProgress();
    removeCounterInProgress();
    removeTypeInProgress();

    return <>
        <div id="assessment-result-outer-container" className={content}>
            <div id="assessment-result-inner-container" className={resultBox}>
                <div id="assessment-result-label" className={resultHdr}>Assessment Results</div>
                <div id="assessment-result-summary" className={resultSummary}>
                    <span id="assessment-result-status">
                        <FiberManualRecordIcon
                            className={
                                submittedData.pass === 'pass'
                                    ? bulletPass
                                    : bulletFail
                            }
                        />
                    </span>
                    <div id="assessment-result-text">
                        You Scored{' '} {submittedData.percentage}
                        % on the Core Assessment
                                </div>
                </div>
                {submittedData.missedQuestions && submittedData.missedQuestions>0 ? <div id="assessment-result-details-container" className={resultDetails}>
                    You missed{' '}
                    {submittedData.missedQuestions}{' '}
                    questions out of{' '}
                    {submittedData.totalQuestions}. The questions you missed were on the topics of{' '}
                    <i>
                        {_.join(submittedData.missedQuestionTopics, ', ')}.
                                </i>               
               </div>:
               <div id="assessment-result-details-container" className={resultDetails}>
               You missed{' '}
               {submittedData.missedQuestions}{' '}
               questions out of{' '}
               {submittedData.totalQuestions}. 
               </div>}
                

            </div>
        </div>
    </>
}

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#337cc0',
    },
    resultSummary: {
        fontFamily: 'Bogle',
        fontSize: 18,
        margin: '12px 0 30px 0',
        display: 'flex',
        flexDirection: 'row',
        '@media (min-width: 0) and (max-width: 360px)': {
            fontSize: 14,
            margin: '12px 0 14px 0',
        },
        '@media (min-width: 361px) and (max-width: 640px)': {
            fontSize: 14,
            margin: '12px 0 14px 0',
            alignItems: 'center'
        },
    },
    resultDetails: {
        fontFamily: 'Bogle',
        fontSize: 18,
        color: '#000',
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 14,
        },
    },
    bulletFail: {
        color: '#ff0000',
        position: 'relative',
        top: 3,
        fontSize: 22,
        '@media (min-width: 0) and (max-width: 640px)': {
            marginRight: 11
        },
    },
    bulletPass: {
        color: 'green',
        position: 'relative',
        top: 3,
        fontSize: 22,
        '@media (min-width: 0) and (max-width: 640px)': {
            marginRight: 11
        },
    },
    resultHdr: {
        fontFamily: 'Bogle',
        fontSize: 30,
        '@media (min-width: 0) and (max-width: 640px)': {
            fontSize: 24,
        },
    },
    resultBox: {
        padding: '30px 30px 54px 30px',
        width: '80%',
        backgroundColor: '#fff',
        margin: '0 auto',
        alignSelf: 'center',
        '@media (min-width: 0) and (max-width: 640px)': {
            padding: '16px 10px 15px 10px'
        },
    },
}

export default AssessmentResult;