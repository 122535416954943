 const appInsightConstants = {

    ACCESS_TOKEN: 'accessToken',
    COURSE_ID: 'courseId',
    ID_TOKEN: 'idToken',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    USER_ID: 'userId',
    JOB_CODE: 'jobCode',
    LAWSON_JOB_CODE: 'lawsonJobCode',
    CLOCK_STATUS: 'clockStatus',
    ROLE: 'role',
    JOB_TITLE: 'jobTitle',
    BADGER_APPLICABLE: 'badgerApplicable',
    AREA: 'area',
    FILE_ID: 'fileId',
    SCHEDULE_ID: 'scheduleId',
    ROSTER_ID: 'rosterId',
    DIVISION: 'division',
    DIVISIONID: 'divisionId',
    PAGE_TYPE: 'pageType',
    TEAM_ID: 'teamId',
    TEAM_NAME: 'teamName',
    WIN_NBR: 'winNbr',
    SESSION_ID: "sessionId",
    SESSSION_START_TS: 'sessionStartDateTs',
    PAGE_OPEN_TIME: 'pageOpenTime',
    PAGE_CLOSE_TIME: 'pageCloseTime',
    TIMESPENT_ON_PAGE: "timeSpentOnPage",
    COUNTRY: 'country',
    STORE_NBR: 'storeNbr',
    DEVICE_TYPE: 'deviceType',
    EVENT_DATE_TS: 'eventDateTimestamp',
    HOME_PAGE_IND: 'homePageIndicator',
    LIBRARY_PAGE_IND: 'libraryPageIndicator',
    CLICKED_AREA: 'clickedArea',
    CLICKED_CATEGORY: 'clickedTopic',
    PAGE_SUBTOPIC: 'pageSubtopic',
    PAGE_NAME: 'pageName',
    SESSION_CLOSE_TS: 'sessionCloseDateTs',
    CAROUSEL_ID: 'carouselID',
    CARD_POSITION: 'cardPosition',
    PAGE_URL: 'pageURL',
    LOGIN: 'login',
    LOGOUT: 'logout',
    CARDCLICK: 'cardclick',
    RECOMMENDEDCARDCLICK: 'recommendedcardclick',
    PAGESCROLLPERCENTAGE: 'pageScrollPercentage',
    RECOMMENDEDCARDCLICKVIEWDESCRIPTION: 'recommendedcardclick-viewdescription',
    SEARCHTERM: 'searchTerm',
    PAGERESULTS: 'pageResults',
    VIDEORESULTS: 'videoResults',
    SEARCHPAGEURL: 'searchPageURL',
    FROMPAGEURL: 'fromPageURL',
    EVENTMAPPINGKEY: 'eventMappingKey',
    SOURCEMAPPINGKEY: 'sourceMappingKey',
    CURRENTMAPPINGKEY: 'currentMappingKey',
    LOADMORENUMBER: 'loadMoreNumber',
    SEARCH: 'search',
    SEARCHLOADMORE: 'search-loadmore',
    ASSESSMENT_ID: 'assessmentID',
    ASSESSMENT_RESULTS: 'assessmentResults',
    PAGE_UID: 'pageUID',
    PAGE_SUBTOPIC_URL: 'subTopicPageURL',
    KNOWLEDGECHECKSTARTED: 'knowledgeCheckStarted',
    KNOWLEDGECHECKCOMPLETED: 'knowledgeCheckCompleted',
    TOPICSUBTOPICPAGE: 'topicorSubtopicPage',
    CLICKEDVIDEORANK: 'clickVideoRank',
    CLICKEDURLRANK: 'clickURLRank',
    CLICKEDVIDEOUID: 'clickedVideoUID',
    CLICKEDURLUID: 'clickedURLUID',
    ACADEMYID: 'academyId',
    PERCENTAGEOFVIDEOPLAYED: 'percentageOfVideoPlayed',
    TIMESPENTONVIDEO: 'timeSpentOnVideo',
    VIDEONAME: 'videoName',
    CLICKEDPAGEURL: 'clickedPageURl',
    SEARCHURL: 'search-URL',
    SEARCHVIDEO: 'search-video',
    PAYTYPE: 'payType',
    TIMESTAMP: 'timestamp',
    ASSESSMENT_CODE_ENTERED: 'assessmentCodeEntered',
    ACADEMY_ID: 'academyId',
    CODE: 'code',
    QUESTIONS: 'questions',
    WM_WEEK:'wmWeek',
    ATTEMPT_NUM:'attemptNum',
    QUESTION_ID:'questionId',
    ANSWER:'answer'
};

export default appInsightConstants;