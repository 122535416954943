

import { deleteGraphQLCache } from '../../cache/indexeddb';


//setters
export const setItem = (name, value) => {
  localStorage.setItem(name, value);
}

export const getItem = (name) => {
  return localStorage.getItem(name);
}

export const removeItem = (name) => {
  localStorage.removeItem(name);
}

export const clear = () => {
  localStorage.clear();
  sessionStorage.clear();

  try {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        if (!cacheName.startsWith("workbox-precache")) {
          caches.delete(cacheName);
        }
      });
    });
    deleteGraphQLCache();

  } catch (err) {
    console.log('Error in clearing indexDb and caches at logout')
  }
}

export const setSessionItem = (name, value) => {
  sessionStorage.setItem(name, value);
}

export const getSessionItem = (name) => {
  return sessionStorage.getItem(name);
}

export const removeSessionItem = (name) => {
  sessionStorage.removeItem(name);
}