import Dexie from 'dexie';
import { mutationNamesList, facOrPartquriesExcludedToBeCached,quriesExcludedToBeCached } from '../../constants/CacheConstants';
import { mutationQueryMapper } from '../mutationquerymapper';
import {getRole, isRoleImpersonated} from "../../utils/userprofile"
import {commonConstants} from '../../constants/CommonConstants'

let db;
const dbName = 'EDUGraphQL';

export const initializeGrpahQLDB = async () => {
  db = new Dexie(dbName);
  db.version(1).stores({
    apis: '++id,query,response',
  });
  db.open();
};

export const cacheAPIResponse = async (query, response) => {
  if (db && db.apis) {
    db.apis.add({ query: query, response: response });
  }
};

export const searchQuery = async (query) => {
  let res = null;
  if (db && db.apis) {
    await db.apis.get({ query: query }).then(function (result) {
      if (result === undefined) {
        res = null;
      } else {
        res = result.response;
      }
    });
  }
  return res;
};

export const clearCache = async () => {
  if (db && db.apis) {
    db.apis.clear();
  }
};

export const clearMappedQueries = async (mutation) => {
  const queryNamesToBeDeleted = getMutationQueryMapper(
    getMutationName(mutation).pop()
  );
  if (db && db.apis) {
    const storedQueries = await getStoredQueries();
    const queriesToBeDeleted = await getQueriesToBeDeleted(
      storedQueries,
      queryNamesToBeDeleted
    );
    if (queriesToBeDeleted.length) {
      db.apis.bulkDelete(
        await db.apis.where('query').anyOf(queriesToBeDeleted).primaryKeys()
      );
    }
  }
};

export const getQueriesToBeDeleted = async (
  storedQueries,
  queryNamesToBeDeleted
) => {
  const queriesToBeDeleted = [];
  storedQueries.map((storedQuery) => {
    queryNamesToBeDeleted&&queryNamesToBeDeleted.split(',').map((queryName) => {
      if (storedQuery.includes(queryName)) {
        queriesToBeDeleted.push(storedQuery);
      }
    });
  });
  return queriesToBeDeleted;
};

export const getStoredQueries = async () => {
  return await db.apis.orderBy('query').keys();
};

export const getMutationName = (mutation) => {

  const mutationName = mutationNamesList.filter((mutationName) => {
    return mutation.includes(mutationName);
  });
  return mutationName;
};

export const canTheQueryBeCached = (query) => {
  if(isRoleImpersonated()){
    return false;
  }
  let queryName = "";
  const role = getRole() || '';
  const excludeQuery = role===commonConstants.PARTICIPANT|| role === commonConstants.FACILITATOR? facOrPartquriesExcludedToBeCached : quriesExcludedToBeCached
  queryName = excludeQuery.filter((queryName) => {
    return query.includes(queryName);
  });
  if(queryName === "") {
    return true;
  }
  else {
    return false;
  }
};

export const getMutationQueryMapper = (mutationName) => {
  return mutationQueryMapper[mutationName];
};

export const deleteGraphQLCache = async () => {
  Dexie.delete(dbName);
};
