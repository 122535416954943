import React from 'react';
import { Snackbar, IconButton, CloseIcon } from '../../../../lib/materialui';
import Button from '../../../common/button'

const SnackbarComponent = ({ open, label, okBtn, autoHide,isToken , isLoad=true}) => {
    return (
        <div id="assessment-snackbar">
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={open}
                message={<div style={{fontFamily: 'Bogle'}}>{label}</div>}
                action={
                    <>
                    {!isToken ? <Button id="assessment-snackbar-button" openEvent={() => {
                            if (okBtn && isLoad) {
                                okBtn(false)
                            } else {
                                window.location.reload()
                            }
                        }} buttonText={`${okBtn ? 'OK' : 'RETRY'}`} buttonType={'primary'} buttonSize={'small'} />
                            : <Button
                                color="secondary"
                                size="small"
                                openEvent={() => window.location.reload()}
                                id="assessment-snackbar-button"
                                buttonText={'Close'} 
                            />
                            }
                        </>
                }
            ></Snackbar>
        </div>
    );
};

export default SnackbarComponent;