import React, { useState, useEffect } from 'react';
import { makeStyles, Dialog } from '../../../lib/materialui';
import AssessmentHeader from './header';
import AssessmentBody from './body';
import AssessmentResult from './result';
import { genAssmtSessionToken } from '../../../utils/common';
import { upsertAssmtToken, validateAssmt } from '../../../dao/assessment';
import { getSessionToken } from '../../../dao/course';
import SnackbarComponent from './snackbar';
import Loading from '../../loading';
import { Messages } from '../../../constants/ErrorConstants';
import {commonConstants} from '../../../constants/CommonConstants'
const AssessmentModal = (props) => {
    const { id, courseName, courseId, assessmentId, type,
        questions, open, handleClose, handleSubmission,
        wmWeek, scoreArr, isResumeAssmt, code, rosterId, start, end, selectedLanguage, selectedLanguagePre
    } = props;
    const useStyles = makeStyles(theme => (styles));
    const classes = useStyles();
    const { page } = classes;
    const [assessmentSubmitted, setAssessmentSubmitted] = useState(false);
    const [submittedData, setSubmittedData] = useState();
    const [canLoadAssmt, setCanLoadAssmt] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const handleAssessmentSubmitted = () => {
        setAssessmentSubmitted(true);
    }

    const handleSubmittetData = (data) => {
        setSubmittedData(data);
    }

    useEffect(() => {
        setCanLoadAssmt(false);
        setErrMsg('');
        if (type === 'PRE') {
            validateAssmtPREState();
        } else {
            validateAssmtPOSTState();
        }
    }, []);

    const validateAssmtPREState = async () => {
        const assmtState = await validateAssmt(courseId, rosterId);
        const assmtStateAssessmentID = selectedLanguagePre?.toLowerCase() == commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase() ? assmtState?.engPreAssessemntID : assmtState?.spanishPreAssessemntID
        if(assmtState && assmtStateAssessmentID === assessmentId){
            if (!assmtState.not_first_attempt) {
                assmtState.can_take_pre ? createSession() : setErrMsg(Messages.assessment_session_error);
            } else {
                setErrMsg('Pretest is no longer available. Contact your Academy Coordinator if there is any issue or concern.');
            }
        } else {
            console.log('validateAssmtPREState.error')
            setErrMsg('An error has occurred, this assessment has not been submitted. Please restart the assessment.');
        }
    }

    const validateAssmtPOSTState = async () => {
        const assmtState = await validateAssmt(courseId, rosterId);
        const assmtStateAssessmentID = selectedLanguage?.toLowerCase() == commonConstants.ASSESSMENT_LANGUAGE_ENGLISH.toLowerCase() ? assmtState?.engPostAssessemntID : assmtState?.spanishPostAssessemntID
        if(assmtState && assmtStateAssessmentID === assessmentId){
            if (assmtState.can_take_post) {
                isResumeAssmt? validateAndCreateSession() : createSession();
            } else {
                setErrMsg(Messages.assessment_session_error);
            }
        } else {
            setErrMsg('An error has occurred, this assessment has not been submitted. Please restart the assessment.');
        }
    }

    const validateAndCreateSession = async () => {
        const activeTokenData = await getSessionToken(assessmentId);
        if (activeTokenData && activeTokenData.length > 0) {
            createSession();
        } else {
            setErrMsg(Messages.assessment_session_error);
        }
    }

    const createSession = async () => {
        const assmtToken = await genAssmtSessionToken();
        const isSessionCreated = await upsertAssmtToken(assessmentId, assmtToken);
        isSessionCreated ? setCanLoadAssmt(true) : setErrMsg('Not able to create a session at the time, Kindly try after sometime');
    }

    const handleSessionErr = (errMsg) => {
        setCanLoadAssmt(false);
        setErrMsg(errMsg);
    }

    return <>
        <Dialog
            id="assessment-dialog"
            keepMounted
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <div id="assessment-dialog-content" className={page}>
                {canLoadAssmt ? (
                    <>
                        <AssessmentHeader assessmentSubmitted={assessmentSubmitted} courseName={courseName} handleClose={handleClose} handleSubmission={handleSubmission} />
                        {
                            !assessmentSubmitted &&
                            <AssessmentBody assessmentId={assessmentId} id={id} courseId={courseId} type={type}
                                questions={questions} isResumeAssmt={isResumeAssmt} assessmentSubmitted={assessmentSubmitted}
                                handleAssessmentSubmitted={handleAssessmentSubmitted} handleSubmittetData={handleSubmittetData}
                                courseName={courseName} wmWeek={wmWeek} scoreArr={scoreArr} handleSessionErr={handleSessionErr} code={code} start={start} end={end} />
                        }
                        {
                            assessmentSubmitted && submittedData && <AssessmentResult submittedData={submittedData} />
                        }
                    </>) : (errMsg === '' ? <Loading title={"Loading Assessmet"} /> : <SnackbarComponent open={true} label={errMsg} autoHide={true} isToken={true} />)
                }
            </div>
        </Dialog>
    </>
}

const styles = {
    page: {
        backgroundColor: '#337cc0',
        height: '100vh'
    },
}

export default AssessmentModal;
