export const ADD_NOTE_MUTATION = `
  mutation contentComment(
    $fileId: String
    $commentBody: String
    $ordering: Int
  ) {
    contentCommentCreate(
      fileId: $fileId
      commentBody: $commentBody
      ordering: $ordering
    ) {
      comment_id
      file_id
      comment_body
      ordering
      deleted
      created
      updated
    }
  }
`;

export const UPDATE_NOTES_MUTATION = `
  mutation contentComment(
    $commentId: String
    $fileId: String
    $commentBody: String
    $ordering: Int
  ) {
    contentCommentUpdate(
      commentId: $commentId
      fileId: $fileId
      commentBody: $commentBody
      ordering: $ordering
    ) {
      comment_id
      file_id
      comment_body
      ordering
      deleted
      created
      updated
    }
  }
`;

export const DELETE_NOTE_MUTATION = `
  mutation{
    contentCommentDelete(commentId: "<<commentId>>", deleted: <<deleted>>)
  }
`;

export const RESET_SCORE_MUTATION = `
mutation{ 
  resetScore(
  roster_id: "<<rosterId>>"
  win: "<<winNbr>>"
  attempt_num: <<attempt_num>>
  type: "<<type>>"
  assessment_id: "<<assessment_id>>")
  }
`;
export const PUBLISH_FILE_MUTATION = `
mutation{ 
  publishFile(fileId: "<<id>>",
 fileType: "<<type>>",
 )
 }
`;

export const PUBLISH_FILES_MUTATION = `
mutation{ 
  publishFiles(files: <<files>>
 )
 }
`;
export const SEND_ACTION_PLAN_MUTATION = `
mutation{ 
  sendActionPlan(roster_id: "<<rosterId>>",
 win: "<<winNbr>>",
 attempt_num: <<attempt_num>>,
 wm_week: "<<wm_week>>",
 assessment_id: "<<assessment_id>>",
 academy_id:<<academy_id>> ){
    unique_id,
    action_plan_sent,
    action_plan_sent_by_win,
    action_plan_sent_on
  }
 }
`;

export const CREATE_COURSE = `
mutation{ 
  createCourse(course: { 
    name: "<<name>>",
    description: "<<description>>",
    parentId: "<<parentId>>",
    win: <<win>>,
    role: "<<role>>",
    is_protected: "<<isProtected>>",
    is_locked: "<<isLocked>>",
    facilitator_guide: "<<facilitatorGuide>>",
    participant_guide: "<<participantGuide>>",
    access_win: "<<accessWin>>",
    lock_access_win: "<<lockAccessWin>>"}){ 
      name 
      id
      contents{
          id
          name
      }
  }
 }
`;

export const UPDATE_COURSE = `
mutation{ 
  updateCourse(course: { 
    id: "<<id>>",
    name: "<<name>>",
    description: "<<description>>",
    parentId: "<<parentId>>",
    win: <<win>>,
    role: "<<role>>",
    is_protected: "<<isProtected>>",
    is_locked: "<<isLocked>>",
    facilitator_guide: "<<facilitatorGuide>>",
    participant_guide: "<<participantGuide>>",
    access_win: "<<accessWin>>",
    lock_access_win: "<<lockAccessWin>>"}){ 
      name 
      id
      contents{
          id
          name
      }
  }
 }
`;

export const DELETE_COURSE = `
mutation{ 
  deleteCourse(course: {id: "<<id>>", deleted: <<deleted>>, win: <<win>>})
 }
`;
export const INVALIDATE_QUESTION_VALIDATION = `
mutation
{
  invalidateQuestionValidation(assessmentId:"<<assessmentId>>", questionId:"<<questionId>>", reason:"<<reason>>", startDate:"<<startDate>>", endDate:"<<endDate>>",
  ){
    assessment_id,
    endDate,
    startDate,
    numberOfRecords,
    question_id,
    reason
  }
}`;

export const INVALIDATE_QUESTION = `
mutation
{  
  invalidateQuestion(numberOfRecords:"<<record>>" assessmentId:"<<assessmentId>>", questionId:"<<questionId>>",
  reason:"<<reason>>", startDate:"<<startDate>>", endDate:"<<endDate>>",passPercent:"<<passPercent>>"
  ){
    assessment_id,
    endDate,
    startDate,
    numberOfRecords,
    question_id,
    reason

  }
}`;

export const CREATE_FOLDER = `
mutation{ 
  createFolder(name: "<<name>>", parentId: "<<parentId>>"){
    id
    name
  }
}`;

export const UPDATE_FOLDER = `
mutation{ 
  updateFolder(id: "<<id>>", name: "<<name>>", parentId: "<<parentId>>", survey_link: "<<surveyLink>>"){
    id
    name
  }
}`;

export const UPDATE_FOLDER_ORDER = `
mutation{ 
  updateFolderOrder(items: <<items>>)
}`;

export const RESYNC_SCORES_BY_ROSTER = `mutation {
  sendScoresToAmsByRoster(rosterId: "<<rosterId>>")
}`;

export const UPDATE_ROSTER = `mutation {
  updateRoster(rosterId: "<<rosterId>>", facilitatorWin: "<<facilitatorWin>>")
}`;

export const SUBMIT_MANUAL_MUTATION = `mutation {
  submitManualScore(
    results: {
      assessment_version: 0
      attempt_num: <<attemptNum>>
      academy_id: <<academyId>>
      pass: <<pass>>
      final_attempt: <<finalAttempt>>
      win: "<<win>>"
      course_id: "<<courseId>>"
      roster_id: "<<rosterId>>"
      facilitator: "<<facilitator>>"
      total_score: "<<totalScore>>"
      created: "<<created>>"
      wm_week: "<<wmWeek>>"
      course_name: "<<courseName>>"
      practical_score: <<practicalScore>>
      type: "POST"
      action_plan_sent: false
    }
  ) {
    attempt_num
    pass
    percentage
    user_win
  }
}
`;

export const SUBMIT_MANUAL_MUTATION_AID = `mutation {
  submitManualScore(
    results: {
      assessment_version: 0
      attempt_num: <<attemptNum>>
      academy_id: <<academyId>>
      pass: <<pass>>
      final_attempt: <<finalAttempt>>
      win: "<<win>>"
      course_id: "<<courseId>>"
      roster_id: "<<rosterId>>"
      assessment_id: "<<assessmentId>>"
      assessment_name: "<<assessmentName>>"
      facilitator: "<<facilitator>>"
      total_score: "<<totalScore>>"
      created: "<<created>>"
      wm_week: "<<wmWeek>>"
      course_name: "<<courseName>>"
      practical_score: <<practicalScore>>
      type: "POST"
      action_plan_sent: false
    }
  ) {
    attempt_num
    pass
    percentage
    user_win
  }
}
`;

export const CREATE_HIGHLIGHT = `
  mutation {
    highlightCreate(
      fileId: "<<fileId>>"
      page_num: "<<pageNum>>"
      target: "<<target>>"
      text: "<<text>>"
      id: "<<id>>"
    ) {
      quote
      ranges {
        start
        startOffset
        end
        endOffset
      }
      page_num
      id
      color_code
    }
  }
`;

export const CREATE_HIGHLIGHT_WITH_VARIABLE = `mutation createHighlight(
    $fileId: String
    $page_num: String
    $target: String
    $text: String
    $id: String
  ) {
    highlightCreate(
      fileId: $fileId
      page_num: $page_num
      target: $target
      text: $text
      id: $id
    ) {
      quote
      ranges {
        start
        startOffset
        end
        endOffset
      }
      page_num
      id
      color_code
    }
  }
`;

export const DELETE_HIGHLIGHT = `
  mutation {
    highlightDelete(id: "<<id>>", fileId: "<<fileId>>", page_num: "<<pageNum>>")
  }
`;

export const INSERT_API_HIT = `
  mutation{ 
    apiHitInsert(
      appname : "<<appName>>"
      payload : "<<payload>>"
      response : "<<response>>")
  }`;

export const UPDATE_HIGHLIGHT_WITH_VARIABLE = `mutation updateHighlight(
      $fileId: String
      $page_num: String
      $target: String
      $id: String
    ) {
      highlightUpdate(
        fileId: $fileId
        page_num: $page_num
        target: $target
        id: $id
      ) {
        quote
        ranges {
          start
          startOffset
          end
          endOffset
        }
        page_num
        id
        color_code
      }
    }`;

export const CREATE_COURSE_WITH_VARIABLE = `
    mutation courseCreate(
      $name: String!
      $description: String
      $parentId: String!
      $win: Int!
      $role: String!
      $isProtected: String,
      $isLocked: String,
      $facilitatorGuide: String,
      $participantGuide: String,
      $flagCrs: String,
      $accessWin: String,
      $lockAccessWin: String
    ){ 
      createCourse(course: { 
        name: $name,
        description: $description,
        parentId: $parentId,
        win: $win,
        role: $role,
        is_protected: $isProtected,
        is_locked: $isLocked,
        facilitator_guide: $facilitatorGuide,
        participant_guide: $participantGuide,
        flagCrs: $flagCrs,
        access_win: $accessWin,
        lock_access_win: $lockAccessWin}){ 
          name 
          id
          errMsg
          contents{
              id
              name
          }
      }
     }
`;

export const UPDATE_COURSE_WITH_VARIABLES = `
  mutation courseUpdate(
    $id: String!
    $name: String!
    $description: String
    $parentId: String!
    $win: Int!
    $role: String!
    $isProtected: String,
    $isLocked: String,
    $facilitatorGuide: String,
    $participantGuide: String,
    $isFeedbackEnabled: Boolean,
    $feedbackId: String,
    $feedbackLink: String,
    $flagCrs: String,
    $accessWin: String,
    $lockAccessWin: String
    $isArchive: String
  ){ 
    updateCourse(course: { 
      id: $id,
      name: $name,
      description: $description,
      parentId: $parentId,
      win: $win,
      role: $role,
      is_protected: $isProtected,
      is_locked: $isLocked,
      facilitator_guide: $facilitatorGuide,
      participant_guide: $participantGuide,
      is_feedback_enabled: $isFeedbackEnabled
      feedback_id: $feedbackId
      feedback_link: $feedbackLink
      flagCrs: $flagCrs,
      access_win: $accessWin,
      is_archive: $isArchive,
      lock_access_win: $lockAccessWin}){ 
        name 
        id
        errMsg
        contents{
            id
            name
        }
    }
  }
`;

// Mutation to save the new Assmt token to the DB
export const UPSERT_ASSMT_TOKEN = `
mutation {
  sessionTokenUpdation(assessmentId: "<<assessmentId>>", tokenId: "<<tokenId>>", group: "<<group>>")
}
`;

export const DELETE_QUESTIONS_BY_USER_INTERFACE = 
`mutation {  deleteQuestionByUserInterface(question_id: "<<question_id>>", assessment_id: "<<assessment_id>>"
  ) {
    question_id
} }`

export const UPDATE_QUESTIONS_SEQUENCE = 
`mutation 
updateQuestionsSequence(
  $updatedQuestions: [EditQuestionType!]!
) {
  updateQuestionsSequence(
    updatedQuestions: $updatedQuestions
  ) {
    error
  }
}
`
export const UPDATE_QUESTIONS_BY_USER_INTERFACE = 
`
  mutation updateQuestionByUserInterface($question_id: String!, $question: String!, $correct_answer: Int!, $answers: [String!]!, $topic: String!, $assessment_id: String!
  ) {
    updateQuestionByUserInterface(
      question_id: $question_id,
      question: $question,
      correct_answer: $correct_answer,
      answers: $answers,
      topic: $topic,
      assessment_id: $assessment_id
    ) {
      error
    }
  }
`;

export const CREATE_QUESTIONS_BY_USER_INTERFACE = 
`
  mutation addQuestionByUserInterface($question: String!, $correct_answer: Int!, $answers: [String!]!, $topic: String!, $assessment_id: String!) {
    addQuestionByUserInterface(
      question: $question,
      correct_answer: $correct_answer,
      answers: $answers,
      topic: $topic,
      assessment_id: $assessment_id
    ) {
      error
    }
  }
`;
export const CREATE_ASSESSMENT_BY_USER_INTERFACE =
  `mutation {  createAssessmentByUserInterface(activeQuestionCount: <<activeQuestionCount>>, name: "<<name>>", passPercentage: <<passPercentage>>, type: "<<type>>", totalAttempts: <<totalAttempts>>, retakeDelay: <<retakeDelay>>, firstRetakeWindow: <<firstRetakeWindow>>, secondRetakeWindow: <<secondRetakeWindow>>, language: "<<language>>", courseId: "<<courseId>>"
  ) {
    assessment_id
    error
} }`

export const UPDATE_ASSESSMENT_BY_USER_INTERFACE =
  `mutation {  updateAssessmentByUserInterface(activeQuestionCount: <<activeQuestionCount>>, name: "<<name>>", assessmentId: "<<assessmentId>>", passPercentage: <<passPercentage>>, type: "<<type>>", totalAttempts: <<totalAttempts>>, retakeDelay: <<retakeDelay>>, firstRetakeWindow: <<firstRetakeWindow>>, secondRetakeWindow: <<secondRetakeWindow>>, language: "<<language>>", courseId: "<<courseId>>"
  ) {
    assessment_id
    error
} }`

export const CREATE_ASSESSMENT = `
{"query":
  "mutation CreateAssessment($courseId: String!, $assessmentFile: Upload!){createAssessment(courseId: $courseId, assessmentFile: $assessmentFile){assessment_id, error}}",
  "variables":{"assessmentFile":null,"courseId":"<<courseId>>"}
}`;

export const UPDATE_ASSESSMENT = `
{"query":
  "mutation UpdateAssessment($courseId: String!, $assessmentId: String!, $assessmentFile: Upload!, $language: String!) {updateAssessment(courseId: $courseId, assessmentId: $assessmentId, assessmentFile: $assessmentFile, language: $language){assessment_id, error}}", "variables":{"assessmentFile":null,"assessmentId":"<<assessmentId>>","courseId":"<<courseId>>","language":"<<language>>"}
}`;

export const DELETE_ASSESSMENT = `
mutation{ 
  deleteAssessment(assessmentId: "<<assessmentId>>", courseId: "<<courseId>>", type: "<<type>>")
}`;

export const RESET_PREV_ASSESSMENT = `
mutation{ 
  resetPrevAssessment(assessmentId: "<<assessmentId>>"){ status, error }
}`;
