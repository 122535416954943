import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { colorCodes } from '../../../constants/ColorConstants';

const Button=({className, buttonText, buttonType,icon,
    openEvent,addIcon})=>{

const useStyles = makeStyles(theme => (styles));
const classes=useStyles();

const {flexRow, button, secondary, freeze, btnRowTxt,addIcontext}=classes;


return <>

<button onClick={openEvent} type="button" tabIndex={0}
className={`eduapp-button ${button} ${className} ${buttonType==='secondary'?secondary:''} ${buttonType==='disabled'?freeze:''}`}
 disabled={buttonType==='disabled'?true:false}>
 <div className={flexRow}>
     <div className={btnRowTxt}>
     <div>{icon}</div> <div className = {addIcon?addIcontext:''}>{buttonText}</div>
    </div>
    </div>
</button>

</>

} // Button

const styles={
    btnRowTxt:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        fontFamily:'Bogle',
        fontWeight:'bold',
        padding:'0 20px',
        whiteSpace:'nowrap'
    },
    buttonTxt:{
       padding:'0 20px',
       whiteSpace:'nowrap',
       display:'flex',
       flexDirection:'row',
       borderRadius:32,
       '@media (min-width:0px) and (max-width:640px)':{
        fontSize:14
       },
    },
    flexRow:{
     display:'flex',
     flexDirection:'row'
    },
    dlIcon:{
        display:'flex',
        fontSize:25.5,
        marginRight:3
    },
    hide:{
        display:'none'
    },
    button:{
        fontSize:16,
        backgroundColor:'#041f41',
        borderRadius:32,
        color:'#fff',
        border:'none',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
         height:50,
         minWidth:130,
         marginLeft:10,
        cursor:'pointer',
        '&:focus':{
            outline:'none',
        },
        '@media (hover: hover)':{
            '&:hover':{
                backgroundColor:'#667b9b'
            },
            '&:focus':{
                outline:'none',
            },
        },
        '@media (min-width:0px) and (max-width:360px)':{
            fontSize:14,
            minWidth:90,
            width:124,
            height:43
           },
    },
    secondary:{
         color:'#041f41 !important',
         backgroundColor:'transparent',
         border:'solid 4px #041f41',
         '&:hover':{
            backgroundColor:'#667b9b',
            color:'#fff',
            border:'none'
        },
        '&:focus':{
            color:'#fff',
            outline:'none'
        }
    },
    freeze:{
        cursor:'default',
        backgroundColor: colorCodes.BG_COLOR_BBC5D4_IMP,
        '&:hover':{
            backgroundColor: colorCodes.BG_COLOR_BBC5D4_IMP
        },
        '&:focus':{
            backgroundColor: colorCodes.BG_COLOR_BBC5D4_IMP
        }
    },
    addIcontext:{
        marginTop:'4px'
    }

} //styles

export default Button;

