export const colorCodes = {
  ERROR_ICON: '#87080e',
  SUCCESS_ICON: '#107015',
  SOLID_BORDER_CODE: 'solid 1px #ffffff',
  SOLID_BORDER_2: 'solid 2px #fff',
  BORDER_BOTTOM_1: 'solid 1px silver',
  SPACE_BETWEEN: 'space-between',
  TEXT_ALIGN_CENTER_IMP: 'center !important',
  PADDING_20_10: '20px 10px 20px 10px',
  MARGIN_22_0_0_1: '22px 0px 0px 1px',
  FONT_BOGLE_IMP: 'Bogle!important',
  FONT_12_IMP: '12px!important',
  FONT_14_IMP: '14px!important',
  FONT_16_IMP: '16px!important',
  SOLID_BORDER_GRAY: 'solid 1px #cbcbcb',
  BOX_SHADOW: '0 5px 15px 0 rgba(15, 15, 15, 0.48)',
  BOX_SHADOW_1_1_4_0: '1px 1px 4px 0px #000',
  BG_WHITE_IMP: '#fff !important',
  PADDING_10_10_10_10: '10px 10px 10px 10px',
  BOX_SHADOW_NEG: '0 -4px 15px 0 rgba(0, 0, 0, 0.15)',
  DISPLAY_NONE_IMP: 'none!important',
  BG_COLOR_BBC5D4_IMP: '#bbc5d4!important',
  BORDER_TOP_BOTTOM_18: '18px solid transparent',
  TRANSITION: 'transform 500ms ease-out'
}