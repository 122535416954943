import { restService } from '../../../apiUtils/datalibrary';

import { getHeaders, getServiceDomain } from "../..";

export const callService = async (apiPath, params, headers, methodType) => {
    const url = await getServiceDomain("common") + apiPath;
    const commonHeaders = getHeaders();
    return await restService(
        url,
        params,
        commonHeaders,
        methodType
    )
}