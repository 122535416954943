import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, ArrowBackIcon } from '../../../lib/materialui';
import { getItem } from '../../../utils/storage';
import { localStorageConstants } from '../../../constants/LocalStorageConstants';
import { headerStyles } from '../../../assets/styles';
import Auth from '../../../security/authentication';
import { getUserDefaultUrl } from '../../../utils/common';
import AssessmentControls from '../../course/assessment-controls';

const Header = props => {
    const {loginScreen} =props;
    const history = useHistory();
    const location = useLocation();
    const [mobMenuVisible, setMobMenuVisible]=useState(false);

    const toggleMobMenu=()=>{
        setMobMenuVisible(!mobMenuVisible);
    }

    const onDashboard=(location.pathname).indexOf('/dashboard')>0 || (location.pathname).indexOf('/assessment')>0 
  
    const useStyles = makeStyles(theme => (headerStyles));
    const classes = useStyles();

    const { logo,  eduText,
        flexColumn, logout, username, bell, mobLogout, logoContainer,
        mobileBack, arrowIcon, bcLink, headerDesktop, insideMobile,mobLogoutMain,
    mobMenu, mobMenuItm, flexRowItem } = classes;

    const auth = new Auth();
    const goHome = () => {
        history.push(getUserDefaultUrl())
    }
   
    const goBack=(event)=>{
        if(location.pathname.indexOf('/course/')===0) {
            window.location.href = '/';
        }else {
            history.goBack();
        }     
        event.preventDefault();
    }

    const userName = `${getItem(localStorageConstants.FIRST_NAME)} ${getItem(localStorageConstants.LAST_NAME)}`;
 
  
    const insidepage =(location.pathname).indexOf('home')===-1;
    const adminHome=(location.pathname).match(/\//gi).length===2 && (location.pathname).indexOf('admin')!=-1;
    const adminInside=(location.pathname).match(/\//gi).length>2 && (location.pathname).indexOf('admin')!=-1;
    const isAppVisitSourceAcademy = getItem(localStorageConstants.SOURCE) === 'academy';

    return <>
       { 
       !onDashboard &&  <div data-testid="header-container">
           <section data-testid="header-section" className={headerDesktop}>

            {
                <div data-testid="header-section-brand-container"
                    onClick={() => goHome()}
                    className={`${logoContainer} ${insidepage && !adminHome && !loginScreen ? insideMobile : ''}`}>
                    <div>
                        <img alt="Graduation Logo" className={logo} src={'/images/coursecard/IconNewIcon.svg'} />
                    </div>
                    <div className={flexColumn}>
                        <span className={eduText}>EDU</span>
                    </div>
                </div>
            }

            {
                (!loginScreen && (insidepage || adminInside) && !adminHome) && props.showAssessmentControls && 
                <AssessmentControls/>
            }

            {
                 (!loginScreen && (insidepage || adminInside) && !adminHome) && <div data-testid="header-section-back-nav-container" className={`${mobileBack} `}>
                    <Link className={bcLink} onClick={(e)=>goBack(e)}>
                        <ArrowBackIcon className={arrowIcon} /><div className={flexColumn}>Back</div>
                    </Link>
                </div>
            }

            <div data-testid="header-section-avatar-controls-container" className={flexRowItem}>
                {/* Retaining for upcoming release: <div> <img alt="Notification Icon" className={bell} src={`/images/coursecard/notifread.png`} />  </div>*/}
                {!loginScreen &&<div data-testid="header-section-avatar-username" className={username}>{userName}</div>}
                {!loginScreen && !isAppVisitSourceAcademy && <div data-testid="header-section-avatar-logout" className={logout} onClick={() => auth.logout()}>LOGOUT</div>}
            
              
                {!loginScreen && <div data-testid="header-section-avatar-controls-container-mobile" className={mobLogoutMain}>  
                    <div data-testid="header-section-avatar-mobile" className={mobLogout} onClick={toggleMobMenu}>
                        <img alt="Person Icon" className={bell} src={`/images/coursecard/person.png`} />
                    </div>
                </div>}
            </div>
        </section>
        {
            mobMenuVisible ? 
                <div data-testid="header-section-avatar-controls-mobile" className={mobMenu}>
                    <div className={mobMenuItm}>{userName}</div>
                    { !isAppVisitSourceAcademy &&
                        <div data-testid="header-section-avatar-mobile-logout" className={mobMenuItm} onClick={() => auth.logout()}>
                            Logout
                        </div>
                    }
                </div>
            : null
        }
        </div>
       }
    </>

}

export default Header;