import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '../../lib/materialui';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import './timeoutDialog.scss';

export const TimeoutDialog = (props) => {
    const { open, logOut, stayActive } = props;

    return (
        <Dialog
            id="idle-timeout-dialog"
            fullWidth={true}
            open={open}
            aria-labelledby="responsive-dialog-title"
        >
            <div id="idle-timeout-dialog-warning-icon" className="centerItem">
                <WarningRoundedIcon className="warningIcon" />
            </div>
            <DialogTitle id="idle-timeout-dialog-title">You've been idle for a while!</DialogTitle>
            <DialogContent id="idle-timeout-dialog-content">
                <DialogContentText id="idle-timeout-dialog-content-text">
                    You will be logged out soon
                </DialogContentText>
                <DialogActions id="idle-timeout-dialog-content-actions">
                    <Button id="idle-timeout-dialog-action-loogout" variant="contained" color="secondary" onClick={logOut}>Logout</Button>
                    <Button id="idle-timeout-dialog-action-continue" variant="contained" color="primary" onClick={stayActive}>Stay Logged in</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
};