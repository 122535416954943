/**
 * @flow
 */
 import {
  graphqlAPIHandler, graphqlAPIHandlerWithVar, restFecthPostHandler,
  restAxiosPostHandler, restAxiosPutHandler, restAxiosGetHandler
} from "./index";
import Auth from '../../security/authentication';
const callService = async (path, query, headers) => {
  var data = await graphqlAPIHandler(path, query, headers);
  if (data === 'INVALID_TOKEN') {
    new Auth().logout();
  }
  var noData = null;
  if (data && data != undefined) {
    if (Object.keys(data).length === 1) {
      var firstKey = Object.keys(data)[0];
      return data[firstKey];
    } else {
      return data;
    }
  } else {
    console.error(
      "Error while fetching data for query :::",
      query
    );
    return noData;
  }
};

const callServiceWithVar = async (path, headers, query, operationName, variables) => {
  var data = await graphqlAPIHandlerWithVar(path, headers, query, operationName, variables);
  if (data === 'INVALID_TOKEN') {
    new Auth().logout();
  }
  var noData = null;
  if (data && data != undefined) {
    if (Object.keys(data).length === 1) {
      var firstKey = Object.keys(data)[0];
      return data[firstKey];
    } else {
      return data;
    }
  } else {
    console.error("Error while fetching data for query with variables :::", query, operationName, variables);
    return noData;
  }
};

const restService = async (path, data, headers, apiType = 'fetchPost') => {
  switch (apiType) {
    case 'fetchPost':
      // eslint-disable-next-line no-case-declarations
      const requestOpts = {
        method: "POST",
        headers: headers,
        body: data,
        redirect: "follow"
      };
      return await restFecthPostHandler(path, requestOpts);
    case 'axiosPost':
      console.log("request options in axios post :::", headers);
      return await restAxiosPostHandler(path, data, headers);
    case 'axiosPut':
      return await restAxiosPutHandler(path, data, headers);
    case 'axiosGet':
      return await restAxiosGetHandler(path, data, headers);
    default:
      break;
  }
};

export { callService, callServiceWithVar, restService };
