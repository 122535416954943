import { callService as graphqlService, callServiceWithVar as graphqlServiceWithVar } from "../../apiUtils/datalibrary";
import { getHeaders, getServiceDomain } from "../";
import {
  searchQuery,
  cacheAPIResponse,
  canTheQueryBeCached,
  clearMappedQueries
} from "../../../cache/indexeddb";
import axios from 'axios';
axios.defaults.withCredentials = true

export const callService = async query => {
  const domain = await getServiceDomain("app");
  const headers = await getHeaders();

  var res;
  if (query.indexOf("query") !== -1) {
    res = await searchQuery(query);
    if (!res) {
      res = await graphqlService(`${domain}/graphql`, query, headers);
      if(canTheQueryBeCached(query)) {
        await cacheAPIResponse(query, res);
      }
    }
  } else {
    clearMappedQueries(query);
    res = await graphqlService(`${domain}/graphql`, query, headers);
  }
  return res;
};

export const callServiceWithVar = async (query, operationName, variables) => {
  const domain = await getServiceDomain("app");
  const headers = await getHeaders();
  return await graphqlServiceWithVar(`${domain}/graphql`, headers, query, operationName, variables);
};

export const callUploadServiceWithVar = async (formData) => {
  const domain = await getServiceDomain("app");
  const url = `${domain}/graphql`;
  const headers = await getHeaders();
  headers['Content-Type'] = 'multipart/form-data';

  return await axios
  .post(url, formData, {headers: headers})
  .then(response => {
    return response;
  })
  .catch(error => {
    console.error("rest api post error: ", error);
    return null;
  });
};

export const callServiceWithTimeout = async (query) => {
  const domain = await getServiceDomain("app");
  const headers = await getHeaders();
  return axios
    .post(
      `${domain}/graphql`,
      { query },
      {headers:headers, timeout: window.ENV.API_REQUEST_TIMEOUT}
    ).then(response => {
      const res = response.status === 200 ? response.data.data : null
      return res
    }).catch(err => {
      console.log("In-App API Error==>", err)
      return null;
    });
};