
export const commonConstants = {
    
    ANONYMOUS_PAGES: [
        "/healthchecker",
        "/impersonateuser",
        "/logout",
        "/?localLogout=true",
        "/usernotfound",
        "/login",
        "/login?fromams",
        "/signup",
        "/signup?fromams",
        "/forgotpassword",
        "/login?redirecturl=",
        "/resetpassword",
        "/activateaccount",
        "/",
        "/graphql",
        "/content/",
        "/external-service-proxy/graphql",
        "/api/fetchUserProfileWithPingfed"
    ],
    NAME_VALIDATION_REG: /^[a-z ,.'`_!-]+$/i,
    EMAIL_VALIDATION_REG: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,

    ADMIN:'administrator',
    SUPER_ADMIN: 'superadmin',
    CONTENT_MANAGER: 'content_manager',
    COORDINATOR: 'coordinator',
    OPERATION_MANAGER: 'operations_manager',
    FACILITATOR: 'facilitator',
    PARTICIPANT: 'participant',

    SC_S :'SC&S',
    S_HO: 'S&HO',
    S_EXT: 'S&EXT',
    EXT_HO: 'EXT&HO',
    SC_EXT: 'SC&EXT',
    SC_HO: 'SC&HO',
    SC_S_HO: 'SC&S&HO',
    SC_S_EXT: 'SC&S&EXT',
    S_HO_EXT: 'S&HO&EXT',
    SC_HO_EXT: 'SC&HO&EXT',

    HOME_OFFICE:'Home Office',
    EXTERNAL: 'Externalized',
    STORE: 'Store',
    SUPPLY_CHAIN: 'Supply Chain',
    DIVISION_OPTION_ALL: 'all',

    AVAILABLE_ROLES: Object.freeze({
        participant: "Participant",
        facilitator: "Facilitator",
        administrator: "Administrator",
        superadmin: "Super Admin",
        coordinator: "Coordinator",
        content_manager: "Content Manager",
        operations_manager: "Operations Manager",
    }),

    ATTENDANCE_PAGE: 'attendancePage',
    ASSESSMENT_PAGE: 'assessmentPage',
    EVENT_ON_CLICK: 'onClick',

    MARK_AS_COMPLETE: 'Mark As Complete',
    INVALIDATE_QUESTION: 'Invalidate Question',
    MANUALLY_ENTERED_SCORE: 'Manually Entered Score',
    ACTION_PLAN: 'Action Plan',
    FLAG_MISSED_ASSESSMENT: 'Flag Missed Assessment',
    CREATE_USER_PROFILE: 'Create User Profile',

    PRIMARY_CHECKBOX: 'primary checkbox',
    MMDDYYYY: 'MM/DD/YYYY',
    YMMDD_HHMMSS: 'Y-MM-DD HH:MM:ss',
    UNAUTHORIZED_PATH: '/unauthorized',
    USERNOTFOUND: '/usernotfound',

    MS_WORD_MIME_TYPE: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ASSESSMENT_LANGUAGE_ENGLISH: 'English',
    ASSESSMENT_LANGUAGE_SPANISH: 'Spanish',
    DEFAULT_PASS_PERCENTAGE: '70'
}


